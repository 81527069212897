import React from "react";
import Router from "./components/Router";
import GlobalStyles from "./components/GlobalStyles";

//app.js 기본 css 파일 호출

const App = () => {
  return (
      <>
        <Router />
        <GlobalStyles />
      </>
  );
};

export default App;
