/**
 * @author hanbin
 * @email
 * @create date 2020-10-26
 * @modify date 2020-10-27
 * @desc Content Header Component
 */

import React from "react";
import {
  ContentBoxContainer,
  IconTitleWrapper,
  IconImage,
  BoxTitle,
  DescriptionButtonWrapper,
  Description,
  Button,
} from "./ContentBoxStyled";

//step 1 : 학기 단원 목록, 2: 단원 수업 목록
const ContentBox = ({
  title,
  description,
  step,
  onClick,
  setCurriculumId,
  setLectureId,
  setMaterialId,
}) => {
  return (
    <ContentBoxContainer>
      <IconTitleWrapper>
        <IconImage
          src={
            step === 0 &&
            "https://cdn.maiclass.com/maiclass/images/jeju/curri/curri-icon-00.png" ||
            step === 1 && 
            "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-icon-03.png" ||
            step === 2 &&
            "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-icon-02.png"
          }
        ></IconImage>
        <BoxTitle>{title}</BoxTitle>
      </IconTitleWrapper>
      <DescriptionButtonWrapper>
        <Description>{description}</Description>
        <Button
          onClick={
            step === 0 
            ?() => {
              onClick();
              setCurriculumId();
            }
            : step === 1
            ? () => {
              onClick();
              setLectureId();
            }
            : () => {
              onClick();
              setMaterialId();
            }
          }
        >
          {step === 2 ? "더보기" : "이동"}
        </Button>
      </DescriptionButtonWrapper>
    </ContentBoxContainer >
  );
};

export default ContentBox;
