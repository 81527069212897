/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-09
 * @desc mainpage
 */

import React, { useEffect, useState } from "react";
import MainpagePresenter from "./MainpagePresenter";
import { roomApi } from "../../api/api-room";
import { projectApi } from "../../api/api-project";

const MainpageContainer = ({ history }) => {
  const [roomList, setRoomList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchRoomData = async () => {
    let result = null;
    try {
      result = await roomApi.getAllRooms();
    } catch (e) {
      console.log(e);
    } finally {
      if (result) {
        setRoomList(result.data.list);
      }
    }
  };

  const fetchProjectData = async () => {
    let result = null;
    try {
      result = await projectApi.getAllProjects();
    } catch (e) {
      console.log(e);
    } finally {
      if (result) {
        setProjectList(result.data.list);
      }
    }
  };

  useEffect(() => {
    fetchRoomData();
    fetchProjectData();
    setIsLoading(false);
  }, []);

  return (
    <MainpagePresenter
      history={history}
      roomList={roomList}
      projectList={projectList}
      isLoading={isLoading}
    ></MainpagePresenter>
  );
};

export default MainpageContainer;
