/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-11
 * @desc mainpage
 */

import React from "react";
import LoginedSidebar from "../sections/LoginedSidebar";
import NotLoginedSidebar from "../sections/NotLoginedSidebar";
import { GoMegaphone } from "react-icons/go";
import { FiChevronDown } from "react-icons/fi";
import ContentBox from "./ContentBox";
import ContentProjectBox from "./ContentProjectBox";
import * as s from "./MainpageStyle";
import Loader from "../Loader";
import {tokenCheck} from "../../util/util";


const MainpagePresenter = ({
  history,
  roomList,
  projectList,
  isLoading,
}) => {
  return (
    <>
      {tokenCheck() ? (
          <LoginedSidebar />
        ) : (
          <NotLoginedSidebar />
        )}
      {
        isLoading ?
          <Loader />
          :
          <s.Container>
            <s.ContentContainer>
              <s.AlarmHeader>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <GoMegaphone size={32} color={"#f0b828"} />
                  <div
                    style={{
                      fontWeight: 700,
                      color: "#f0b828",
                      fontSize: 24,
                      marginLeft: "3%",
                    }}
                  >
                    알림
              </div>
                  <s.AlarmText marginLeft={3}>
                    {/* 3학년 1반 */}
              </s.AlarmText>
                  <s.AlarmText marginLeft={12}>
                    {/* 3학년 1반 10월 22일날 단소 챙겨오세요~! */}
              </s.AlarmText>
                </div>
                <FiChevronDown size={32} />
              </s.AlarmHeader>
              <s.ContentsWrapper>
                <ContentBox
                  title={"진행중인 학급"}
                  contentList={roomList}
                ></ContentBox>
                <ContentProjectBox
                  title={"최근 프로젝트"}
                  contentList={projectList}
                ></ContentProjectBox>
                <ContentBox title={"내 질문"}></ContentBox>
              </s.ContentsWrapper>
            </s.ContentContainer>
          </s.Container>
      }
    </>
  );
};

export default MainpagePresenter;
