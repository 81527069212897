import styled from "styled-components";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const ContentsContainer = styled.section`
  padding: 3% 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  height: 35vh;
  width: 100%;
`;

export const ContentTitle = styled.h1`
  font-size: 30px;
`;

export const ContentFlexRowDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5%;
  padding: 0 2%;
`;

//RoomBox

export const RoomBox = styled.div`
  width: 20%;
  height: 30vh;
  border: 1px solid #5e5e5e;
  border-radius: 10px;
  margin-right: 3%;
  margin-bottom: 3%; 
  cursor: pointer; 
`;

export const BoxImage = styled.div`
  height: 50%;
  width: 100%;
  background-image: url("https://cdn.maiclass.com/maiclass/images/intro/classes/classroom-example.jpg");
  background-size: cover;
  background-position: center center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: none;
`;

export const BoxTextWrapper = styled.div`
  height: 50%;
  width: 100%;
  padding: 15% 0;
  text-align: center;
`;

export const TextTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
`;

export const TextSubTitle = styled.h3`
  font-size: 18px;
  margin-top: 8%;
`;
