/*********************************
//*** @Author : jeongin ***
//*** @Date : 2020.11.03 ***
//*** @Title : api login ***
*********************************/

import {api} from "./api-base"

export const loginApi = {
    postInformation: (Data) => 
      api.post("/user/login", Data)
};