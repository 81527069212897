/**
 * @author hong
 * @email
 * @create date 2020-11-2
 * @modify date 2020-11-2
 * @desc 엔트리 컴포넌트
 */
import React from "react";
import * as s from "../ComponentBoxStyled";

const ContentsImg = ({
    url,
}) => {
    return (
        <s.ImgSection>
        <s.Img src={url}/>
        </s.ImgSection>
    );
}

export default ContentsImg;