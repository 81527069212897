import { Link } from "react-router-dom";
import styled from "styled-components";

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
`;

// 1080px 기준은 Container로
const Container = styled.div`
  width: 1080px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 25px;
`;

const HeadVisualWrap = styled.div`
  background: url("https://cdn.myzzym.com/maiclass/images/intro/ar/ar-head-image.jpg")
    no-repeat center;
  height: 300px;
  position: relative;
  background-size: cover;
`;

const HeadTextWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 1080px;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: 30;
  text-align: right;
`;

const HeadTextContent = styled.p`
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  line-height: 1.6;
  letter-spacing: -0.25px;
`;

const MainTitle = styled.h1`
  font-weight: bold;
  font-size: 46px;
  color: #fff;
  line-height: 1.2em;
`;

const SubTitle = styled(MainTitle)`
  font-weight: 400;
  font-size: 24px;
  text-align: right;
  margin-bottom: 10px;
`;

const MainStrong = styled.strong`
  font-weight: bold;
  font-size: 46px;
  color: #f0b828;
`;

///////////////
//  Content  //
///////////////

const ContentWrap = styled(Container)`
  /* height: 400px; */

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Content = styled.div`
  margin-top: 80px;
`;

const BigInnerContent = styled.section`
  width: 520px;
  height: 450px;
  float: left;
  margin-bottom: 13px;
`;

const IconBox = styled.ul`
  position: relative;
  float: left;
  width: 185px;
  height: 100%;
`;

const IconItem = styled.li`
  width: 30px;
  height: 30px;
  background: url(${(props) => props.bgUrl}) no-repeat
    center;
  background-size: contain;
  position: absolute;
  opacity: 0.5;
`;

const Elementary = styled.div`
  width: 98%;
  height: 100%;
  border: 1px solid #5e5e5e;
  border-radius: 10px;
  background-color: #fff;
  position: relative;


  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Middle = styled(Elementary)`
  width: 100%;
  height: 48%;

  &:first-child {
    margin-bottom: 2%;
  }
`;

const SchoolTitle = styled.h3`
  font-size: 28px;
  font-weight: 500;
  margin-top: 20px;
`;

const TitleBox = styled.div`
  width: 145px;
  float: left;

  position: absolute;
  bottom: 35px;
  left: 30px;
`;

const SchoolIcon = styled.div`
  width: 50px;
  height: 50px;
  background: url(${(props) => props.bgUrl}) no-repeat
    center;
  background-size: contain;
`;

const CurriContents = styled.div`
  width: 280px;
  height: 100%;
  float: right;
  padding: 20px 0;
  box-sizing: border-box;
`;

const CurriListBox = styled.div`
  width: 100%;
  height: 50%;
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
`;

const CurriList = styled.ul`
  width: 130px;
  float: left;
  margin-right: 10px;
`;

const CurriListItem = styled.li`
  background-color: ${(props) =>
    props.bgColor ? "#f0b828" : "#cccccc"};
  padding: 10px 0;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px;

  &:last-child {
    margin: 0;
  }
`;

const AIElementary = styled.div`
  width: 98%;
  height: 100%;
  border: 1px solid #5e5e5e;
  border-radius: 10px;
  background-color: #fff;
  position: relative;


  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const CurriTitle = styled.p`
  font-size: 15px;
  color: #cccccc;
  font-weight: 500;
  margin-bottom: 12px;
`;

const CLink = styled.a`
  color: #fff;
`;

const Smalltext = styled.h4`
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 5px;

  &:last-child {
    margin: 0;
  }

`;

export const SLink = styled(Link)`
  color: #fff;
`;

export const AIContainer = styled(BigInnerContent)`
  height: 170px;
`;

export {
  Container,
  OuterContainer,
  HeadVisualWrap,
  HeadTextWrap,
  HeadTextContent,
  MainTitle,
  SubTitle,
  MainStrong,
  Title,
  ContentWrap,
  BigInnerContent,
  Elementary,
  IconBox,
  IconItem,
  SchoolTitle,
  TitleBox,
  SchoolIcon,
  CurriContents,
  CurriListBox,
  CurriList,
  CurriListItem,
  CurriTitle,
  Middle,
  Content,
  CLink,
  AIElementary,
  Smalltext,
};
