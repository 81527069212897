/**
 * @author hanbin
 * @email
 * @create date 2020-10-26
 * @modify date 2020-10-27
 * @desc Content Header Component
 */

import React from "react";
import { HiOutlineArrowCircleLeft } from "react-icons/hi";
import {
  HeaderSection,
  ButtonTitleWrapper,
  Title,
  Description,
} from "./ContentHeaderStyled";

const ContentHeader = ({
  history,
  step,
  stageText,
  goToStep1,
  goToStep2,
  title,
}) => {
  return (
    <HeaderSection>
      <ButtonTitleWrapper>
        <HiOutlineArrowCircleLeft
          onClick={() => {
            step === 1
              ? history.push("/curriculum")
              : step === 2 
              ? goToStep1()
              : goToStep2()
          }}
          size={30}
        />
        <Title>{step === 1 ? "컨텐츠 목록" : title}</Title>
      </ButtonTitleWrapper>
      <Description>{stageText}</Description>
    </HeaderSection>
  );
};

export default ContentHeader;
