import React from "react";
import {
  Container,
  Contents,
  Title,
  IntroContents,
  IntroContentsBox,
  IntroContentsBoxDown,
  IntroContentsDown,
  IntroIcon,
  IntroIconDown,
  IntroText,
  IntroTextDown,
  IntroInfo,
  IntroInfoDown,
  IntroSubText,
  IntroSubTextDown,
  IntroName,
  IntroNameDown,
  OrangeBar,
  OrangeContents,
  OrangeIconBox,
  OrangeIcon,
  OrangeTitle,
  OrangeText,
  Reason,
  ReasonContents,
  ReasonInner,
  Image,
  ImageRight,
  TextBox,
  TextBoxRight,
  ReasonTitle,
  ReasonSub,
  Strong,
} from "./IntroductionStyled";

const Introduction = (props) => (
  <>
    <Container>
      <Contents>

        {/* 공동대표소개 */}

        <IntroContents>
          <IntroIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/intro-icon-01.png"}></IntroIcon>
            <IntroContentsBoxDown>
              <IntroInfoDown>
                <IntroNameDown bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/intro-name-03.png"}></IntroNameDown>
                <IntroSubTextDown>
                    공학박사·컴퓨터<br/>
                    前 KDDI R&D Labs(일본) 책임연구원
                </IntroSubTextDown>
              </IntroInfoDown>
  
              <IntroTextDown>
                포스트 COVID-19 시대의 디지털 포용 사회도약이 필요합니다.<br/>
                디지털 소외층의 격차를 해소하고 융합형 인재 양성을 위해<br/>
                자기주도형 AI 코딩 학습 시스템을 개발하고 있습니다.
              </IntroTextDown>
            </IntroContentsBoxDown>
        </IntroContents>


        <IntroContents>
            <IntroContentsBox>
              <IntroText>
                  인공지능 핵심 기술의 원리는 수학을 기반으로 하고 있습니다. <br/>
                  초,중,고에서 배우는 수학의 모든 과정과 <br/>
                  연계할 수 있는 교육 콘텐츠를 개발하고 있습니다.
              </IntroText>
  
              <IntroInfo>
                <IntroSubText>
                    이학박사 ・ 수학<br/>
                    前 대학교 수학과 교수
                </IntroSubText>
                <IntroName bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/intro-name-01.png"}></IntroName>
              </IntroInfo>
            </IntroContentsBox>
        </IntroContents>

        <IntroContentsDown>
          <IntroIconDown bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/intro-icon-02.png"}></IntroIconDown>

            <IntroContentsBoxDown>
              <IntroInfoDown>
                <IntroNameDown bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/intro-name-02.png"}></IntroNameDown>
                <IntroSubTextDown>
                    공학박사 ・ 컴퓨터<br/>
                    前 국가수리과학연구소 센터장
                </IntroSubTextDown>
              </IntroInfoDown>
  
              <IntroTextDown>
                  인공지능 시대에는 Mathware 전문가가 되어야 합니다. <br/>
                  수학적 원리를 이해하고 알고리즘을 직접 만들고, <br/>
                  프로그래밍 할 수 있는 서비스를 개발하고 있습니다. <br/>
              </IntroTextDown>
            </IntroContentsBoxDown>
        </IntroContentsDown>

      </Contents>

    {/* 주황색 bar */}
    <OrangeBar>
        <OrangeContents>
          <OrangeTitle>최고 수준의 연구진들이 개발한 수학 교육 및 인공지능 교육 콘텐츠</OrangeTitle>
          <OrangeIconBox>
            <OrangeIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/orange-icon-01.png"} style={{width:"180px"}}></OrangeIcon>
            <OrangeIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/orange-icon-02.png"}></OrangeIcon>
            <OrangeIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/orange-icon-03.png"}></OrangeIcon>
            <OrangeIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/orange-icon-04.png"}></OrangeIcon>
            <OrangeIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/orange-icon-05.png"}></OrangeIcon>
            <OrangeIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/orange-icon-06.png"} style={{width:"200px"}}></OrangeIcon>
            <OrangeIcon bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/orange-icon-07.png"}></OrangeIcon>
          </OrangeIconBox>
          <OrangeText>마이클래스의 모든 콘텐츠는 국내외 유수 기업/대학 출신의 전문가가 연구 개발하고 있습니다.</OrangeText>
        </OrangeContents>
    </OrangeBar>


    {/* 마이클래스 선택하는 이유 */}
    <Reason>
      <Title>마이클래스를 선택해야 하는 이유</Title>
      <ReasonContents>

        <ReasonInner>
          <Image bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/reason-icon-01.png"}></Image>
          <TextBoxRight>
            <ReasonTitle>
              초・중・고 모든 <Strong>수학 교과과정</Strong>과 <br/>
              일치되는 <Strong>인공지능 교육</Strong>
            </ReasonTitle>
            <ReasonSub>
              초등학교 1학년부터 고등학교 3학년까지 <br/>
              학교 수학교육 과정과 일치되는 인공지능 교육 콘텐츠를 제공합니다. <br/>
              각 단원에서 수학의 원리를 이해하고 <br/>
              이와 연관된 알고리즘, 프로그래밍 과정을 통해 <br/>
              mathware 전문가로 성장할 수 있습니다.
            </ReasonSub>
          </TextBoxRight>
        </ReasonInner>

        <ReasonInner>
          <TextBox>
            <ReasonTitle>
              각 <Strong>학생별</Strong> 프로젝트 <Strong>포트폴리오</Strong>
            </ReasonTitle>
            <ReasonSub>
              마이클래스에서 진행되는 모든 수업에 참여하는 학생들은 <br/>
              개인 페이지에 본인의 수업 자료, 코딩 결과, 아이디어 등을 <br/>
              저장하고 관리할 수 있으며, 이러한 자료들은 <br/>
              학생별 프로젝트 포트폴리오로 활용할 수 있습니다. <br/>
            </ReasonSub>
          </TextBox>
          <ImageRight bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/reason-icon-02.png"}></ImageRight>
        </ReasonInner>

        <ReasonInner>
          <Image bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/reason-icon-03.png"}></Image>
          <TextBoxRight>
            <ReasonTitle>
              <Strong>수학</Strong>과 <Strong>인공지능 교육</Strong>에 <br/>
              최적화된 maiclass.com
            </ReasonTitle>
            <ReasonSub>
              마이클래스에서 연구진이 개발한 <br/>
              모든 콘텐츠는 maiclass.com을 통해 제공됩니다. <br/>
              maiclass.com은 별도의 설치없는 프로그래밍 환경을 제공하고, <br/>
              국내 유일의 개인 맞춤형 데이터 허브를 지원하여 <br/>
              인공지능 시대 핵심 기술(AI, 빅데이터)을 학습할 수 있습니다. <br/>
            </ReasonSub>
          </TextBoxRight>
        </ReasonInner>

        <ReasonInner>
          <TextBox>
            <ReasonTitle>
              <Strong>실시간 원격 수업</Strong> 지원
            </ReasonTitle>
            <ReasonSub>
              모든 콘텐츠는 maiclass.com을 통해 수업을 진행할 수 있으며, <br/>
              화상솔루션(zoom)도 연동되어 있어 <br/>
              쉽게 실시간 원격수업을 진행할 수 있습니다.  <br/>
            </ReasonSub>
          </TextBox>
          <ImageRight bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/main/reason-icon-04.png"}></ImageRight>
        </ReasonInner>
        

      </ReasonContents>
      
    </Reason>


    </Container>
  </>
);

export default Introduction;
