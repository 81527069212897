/**
 * @author hanbin
 * @email
 * @create date 2020-10-27
 * @modify date 2020-10-27
 * @desc curriculum api
 */

import {api, loginedApi} from "./api-base";

export const curriculumApi = {
  getLectureList: (curriculumId) =>
    api.get(`/lecture/list/${curriculumId}`),
  getMaterialList: (lectureId) =>
    api.get(`/material/list/${lectureId}`),
  getMaterialDetail: (materialId) =>
    loginedApi.get(`/material/detail/${materialId}`),
};
