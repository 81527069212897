import styled from "styled-components";

// header 중간
const Container = styled.div`
  width: 100%;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Contents = styled.div`
  width: 1080px;
  height: 100%;
  margin: 80px auto;
  position: relative;
`;

const Title = styled.h3`
  color: #221E1F;
  font-size: 30px;
  font-weight: 500;
  /* padding-top: 50px; */
`;

//공동대표 소개
const IntroContents = styled.div`
    width: 100%;
    /* height: 180px; */
    padding: 45px 0;
    border-bottom: 1px solid #8f8f8f;
    position: relative;

    &::after {
      content: "";
      display: block;
      clear: both;
    }
`;

const IntroContentsDown = styled(IntroContents)`
    border: none;
    padding-bottom: 80px;
`;

const IntroContentsBox = styled.div`
  width: 100%;
  /* position: absolute; */
  
`;

const IntroContentsBoxDown = styled(IntroContentsBox)`
  top: 30%;
`;

const IntroIcon = styled.div`
  background: url(${(props) => props.bgUrl}) no-repeat center;
  width: 50px;
  height: 30px;
  position: absolute;
  top: -30px;

`;

const IntroIconDown = styled(IntroIcon)`
  top: unset;
  bottom: 0;
  right: 0;
`;

const IntroText = styled.p`
    line-height: 1.3em;
    float: left;
`;

const IntroTextDown = styled(IntroText)`
  float: right;
  text-align: right;
`;

const IntroInfo = styled.div`
  float: right;

  &::after {
      content: "";
      display: block;
      clear: both;
    }
`;

const IntroInfoDown = styled(IntroInfo)`
  float: left;
`;

const IntroSubText = styled.p`
    color: #cccccc;
    text-align: right;
    font-size: 15px;
    float: left;
    margin: 30px 30px 0 0;
`;

const IntroSubTextDown = styled(IntroSubText)`
    text-align: left;
    float: left;
`;

const IntroName = styled.div`
  background: url(${(props) => props.bgUrl}) no-repeat center;
  width: 175px;
  height: 80px;
  float: right;
`;

const IntroNameDown = styled(IntroName)`
  float: left;
`;

// 주황색 바
const OrangeBar = styled.div`
    width: 100%;
    height: 260px;
    background-color: #F0B828;
`;

const OrangeContents = styled.div`
  width: 1080px;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
  box-sizing: border-box;
`;

const OrangeTitle = styled(Title)`
    text-align: center;
`;

const OrangeIconBox = styled.ul`
  width: 100%;
  height: 50px;
  padding: 40px 0;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

`;

const OrangeIcon = styled.li`
  width: 100px;
  height: 100%;
  background: url(${(props) => props.bgUrl}) no-repeat center;
  background-size: contain;
  float: left;
  margin-right: 30px;

  &:last-child {
    margin: 0;
  }
`;

const OrangeText = styled(IntroText)`
    text-align: center;
    float: unset;
`;


// 마이클래스 선택 이유

const Reason = styled(Contents)`
  height: 1560px;
  margin : 120px auto -80px;
`;

const ReasonContents = styled.ul`
  width: 100%;
  
`;

const ReasonInner = styled.li`
  width: 100%;
  height: 290px;
  padding: 38px 0;

  &::after {
      content: "";
      display: block;
      clear: both;
  }
`;

const Image = styled.div`
  width: 50%;
  height: 250px;
  float: left;
  background: url(${(props) => props.bgUrl}) no-repeat center;
  background-size: contain;
  margin-top: 30px;
  /* margin-left: 50px; */
`;

const ImageRight = styled(Image)`
  /* float: right; */
`;

const TextBox = styled.div`
  width: 50%;
  float: left;
  margin-top: 60px;
`;

const TextBoxRight = styled(TextBox)`
  float: right;
`;

const ReasonTitle = styled.h5`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  line-height: 1.2em;
`;

const ReasonSub = styled(IntroText)`
  color: #5e5e5e;
`;

const Strong = styled.strong`
  color: #F0B828;
`;

export {
  Container,
  Contents,
  Title,
  IntroContents,
  IntroContentsBox,
  IntroContentsBoxDown,
  IntroContentsDown,
  IntroIcon,
  IntroIconDown,
  IntroText,
  IntroTextDown,
  IntroInfo,
  IntroInfoDown,
  IntroSubText,
  IntroSubTextDown,
  IntroName,
  IntroNameDown,
  OrangeBar,
  OrangeContents,
  OrangeTitle,
  OrangeIconBox,
  OrangeIcon,
  OrangeText,
  Reason,
  ReasonContents,
  ReasonInner,
  Image,
  ImageRight,
  TextBox,
  TextBoxRight,
  ReasonTitle,
  ReasonSub,
  Strong,
};
