/**
 * @author jeongin
 * @email
 * @create date 2020-11-16
 * @modify date 2020-11-16
 */
import { loginedApi } from "./api-base";

export const datahubApi = {
  getTemperatureData: (data) =>
    loginedApi.get("/sensordata/temperature",{
      params: data
    }),
  getHumidityData: (data) =>
    loginedApi.get("/sensordata/humidity",{
      params: data
    }),
  getDistanceData: (data) =>
    loginedApi.get("/sensordata/distance",{
      params: data
    }),
  getSoundData: (data) =>
    loginedApi.get("/sensordata/sound",{
      params: data
    }),
  downloadTemperatureData: (data) =>
    loginedApi.get("/sensordata/temperature/download",{
      params: data
    }),
  downloadHumidityData: (data) =>
    loginedApi.get("/sensordata/humidity/download",{
      params: data
    }),
  downloadDistanceData: (data) =>
    loginedApi.get("/sensordata/distance/download",{
      params: data
    }),
  downloadSoundData: (data) =>
    loginedApi.get("/sensordata/sound/download",{
      params: data
    }),
};
