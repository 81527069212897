/**
 * @author hong
 * @email
 * @create date 2020-11-2
 * @modify date 2020-11-2
 * @desc 엔트리 컴포넌트
 */
import React from "react";
import {
    TitleButton,
    ButtonSection,
    BtnLabel,
} from "../ComponentBoxStyled";
import { FaCode, FaPlay } from 'react-icons/fa';

const ContentsEntry = ({
    url,
    code,
}) => {

    return (
        <ButtonSection>
            <a target="_blank" href={url}>
                <TitleButton><FaPlay size={15}/></TitleButton>
                <BtnLabel>엔트리 실행</BtnLabel>
            </a>
            <a target="_blank" href={code}>
                <TitleButton><FaCode size={15}/></TitleButton>
                <BtnLabel>코드 보기</BtnLabel>
            </a>
        </ButtonSection>
    );
}

export default ContentsEntry;