import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 1400px;
  margin: 0 auto;
`;

const LoginedSidebar = styled.nav`
  width: 130px;
  height: 100%;
  background-color: white;
  border: 0.75px solid #ededed;
  color: #303030;
  position: fixed;
  z-index: 990;
`;

const AdminInfo = styled.div`
  width: 100%;
  height: 160px;
  text-align: center;
  margin-top: 50px;
  border-bottom: 0.75px solid #ededed;
  background-color: #fff;
`;

export const ProfileText = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20%;
`;

export const SubText = styled.div`
  font-size: 16px;
  color: #5e5e5e;
  font-weight: 300;
  margin-top: 20%;
`;

const List = styled.ul`
  width: 100%;
`;

const SLink = styled(Link)`
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: gray;
`;

const SLinkText = styled.h3`
   &:hover{
    color: #F0B828;
  }
`;

const Icon = styled.div`
  background: url(${(props) => props.bgUrl}) no-repeat
    center;
  width: 38px;
  height: 30px;
`;

const Logout = styled.button`
  margin-top: 200%;
  margin-left: 10%;
  color: #F0B828;
  font-weight: bold;
  font-size: 20px;
  outline: none;
`;


export {
  Container,
  LoginedSidebar,
  AdminInfo,
  List,
  SLink,
  Icon,
  Logout,
  SLinkText
};
