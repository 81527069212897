import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

//intro
import HomeIndex from "./home/HomeIndex";
import HeaderNavbar from "./sections/HeaderNavbar";
import Footer from "./sections/Footer";
import * as content from "./sections/ContentStyled";
import Curriculum from "./curriculum/index";
import AiEdu from "./aiedu/index";
import Start from "./howtostart/index";
import Project from "./project/index";
import AddProject from "./project/add";
import UpdateProject from "./project/update";

//maiclass content
import Mainpage from "./mainpage/index";
import CurriculumContent from "./curriculum/curriculumcontent";
import AiEduContent from "./aiedu/curriculumcontent";
import Classroom from "./classroom/index";
import Datahub from "./datahub";
import ClassroomCurriculum from "./classroom/ClassroomCurriculum";
import ClassroomMeetingRoom from "./classroom/ClassroomMeetingRoom";

//login
import SignView from "./auth/index";

import ProjectView from "./project/view";
import ScrollToTop from "./ScrollToTop";
import Zero from "./zero";
/*
 * path 는 URL 이다.
 * 사용자가 이벤트 발생 시 이에 해당하는 컴포넌트로 이동한다.
 * Router components props: component vs render
 */

function Routers(props) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <content.Container>
          <HeaderNavbar></HeaderNavbar>
          {/* 디폴트 라우터는 모든 라우터들에게 props를 준다!!!! */}
          <Switch>
            <Route path="/" exact component={HomeIndex} />

            <Route
              path="/Zero"
              exact
              component={Zero}
            />

            <Route
              path="/curriculum"
              exact
              component={Curriculum}
            />
            <Route path="/aiedu" exact component={AiEdu} />
            <Route path="/start" exact component={Start} />

            <Route
              path="/curriculum/:curriculumId"
              exact
              component={CurriculumContent}
            />

            <Route
              path="/aiedu/:curriculumId"
              exact
              component={AiEduContent}
            />

            <Route
              path="/mainpage"
              exact
              component={Mainpage}
            />

            <Route
              path="/classroom"
              exact
              component={Classroom}
            />

            <Route
              path="/classin/curriculum"
              exact
              component={ClassroomCurriculum}
            />

            <Route
              path="/classin/meetingRoom"
              exact
              component={ClassroomMeetingRoom}
            />

            <Route path="/login" exact component={SignView} />

            <Route
              path="/project"
              exact
              component={Project}
            />

            <Route
              path="/project/add"
              exact
              component={AddProject}
            />

            <Route
              path="/project/update/:projectId"
              exact
              component={UpdateProject}
            />

            <Route
              path="/project/read/:projectId"
              exact
              component={ProjectView}
            />

            <Route
              path="/datahub"
              exact
              component={Datahub}
            />


            {/* 사용자가 잘못된 경로로 접근 시 메인페이지로 리다이렉트한다. */}

            <Redirect from="*" to="/" />

          </Switch>
        </content.Container>
        {/* <Footer /> */}
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Routers;
