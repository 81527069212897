/**
 * @author hong
 * @email
 * @create date 2020-11-2
 * @modify date 2020-11-2
 * @desc pdf 컴포넌트
 */
import React, { useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import {
  PDFDocumentWrapper,
  PageText,
  PageSection,
  Button,
} from "../ComponentBoxStyled";
import {FaArrowAltCircleLeft, FaArrowAltCircleRight} from 'react-icons/fa';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContentsPdf = ({
  url,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const prevPage = () => {
    if(pageNumber > 1) 
      setPageNumber(pageNumber-1);
  };
  const nextPage = () => {
    if(pageNumber <= numPages)
      setPageNumber(pageNumber+1);
  };

  return (
    <PDFDocumentWrapper>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
      >
        <Page pageNumber={pageNumber} renderTextLayer={false} scale={2}/>
        <PageSection>
          <Button onClick={prevPage}><FaArrowAltCircleLeft size={20}/></Button>
          <PageText>Page {pageNumber} of {numPages}</PageText>
          <Button onClick={nextPage}><FaArrowAltCircleRight size={20}/></Button>
        </PageSection>
      </Document>
    </PDFDocumentWrapper>
  );
}


export default ContentsPdf;