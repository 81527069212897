/*********************************
//*** @Author : ydoni ***
//*** @Date : 2020.09.15 ***
//*** @Title : HeaderNavbar (admin menubar) ***
*********************************/

import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
    Header,
    Container,
    LogoBox,
    LogoImage,
    LogoSubText,
    Menu,
    MenuItem,
    MenuTitle,
} from "./HeaderNavbarStyled";
import { tokenCheck } from "../../util/util"

export default withRouter(({ location: { pathname }, history }) => {
    
    return (
        <div>
            <Header>
                <Container>

                    <LogoBox>
                        <Link to="/">
                            <LogoImage
                                alt="myzzym-logo"
                                src="https://cdn.maiclass.com/maiclass/images/maiclass_bi.png"
                            />
                            {/* <LogoSubText>
                                국가수리과학연구소<br />
                        연구원 창업기업
                    </LogoSubText> */}
                        </Link>
                    </LogoBox>
                    <Menu>
                    <MenuItem
                            current={
                                pathname.includes("zero")
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/zero",
                                }}
                            >
                                <MenuTitle>제로프로젝트</MenuTitle>
                            </Link>
                        </MenuItem>
                        <MenuItem
                            current={
                                !pathname.includes("classin") &&
                                pathname.includes("/curriculum")
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/curriculum",
                                }}
                            >
                                <MenuTitle>School</MenuTitle>
                            </Link>
                        </MenuItem>

                        <MenuItem
                            current={
                                pathname.includes("/aiedu")
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/aiedu",
                                }}
                            >
                                <MenuTitle>AI 교육</MenuTitle>
                            </Link>
                        </MenuItem>

                        <MenuItem
                            current={
                                pathname.includes("/start")
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/start",
                                }}
                            >
                                <MenuTitle>HOW TO START</MenuTitle>
                            </Link>
                        </MenuItem>

                        <MenuItem
                            current={
                                pathname.includes("/mainpage") ||
                                pathname.includes("/classroom") ||
                                pathname.includes("/project") ||
                                pathname.includes("/datahub") ||
                                pathname.includes("/classin")
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/mainpage",
                                }}
                            >
                                <MenuTitle>MAI</MenuTitle>
                            </Link>
                        </MenuItem>
                    </Menu>
                </Container>
            </Header>
        </div>
    )
});
