/*********************************
//*** @Author : jeongin ***
//*** @Date : 2020.11.16 ***
//*** @Title : 내 데이터 페이지 presenter
*********************************/

/**
 * @author ydoni
 * @date 2021.2.24
 * @description style 수정
 */

import React from "react";
import LoginedSidebar from "../sections/LoginedSidebar";
import NotLoginedSidebar from "../sections/NotLoginedSidebar";
import { Line } from "react-chartjs-2";
import { tokenCheck } from "../../util/util";
import Datetime from "react-datetime";

import * as s from "./SensorDataPresenterStyled";

const SensorDataPresenter = ({
    nowState,
    setNowState,
    lineState,
    setLineState,
    lineColor,
    sensorValue,
    setSensorValue,
    dataLength,
    dateForm,
    setStart,
    setEnd,
    searchData,
    downloadData,
}) => {

    return (
        <>
            {tokenCheck() ? (<LoginedSidebar />) : (<NotLoginedSidebar />)}
            <s.Container>
                <s.ContentContainer>
                    <s.HeadArea>
                        <s.HeadLeftArea>
                        <s.FirstTitle>내 {nowState} 데이터</s.FirstTitle>
                        <s.DatetimeDiv>
                            <Datetime locale="ko" dateFormat="YYYY-MM-DD" timeFormat="HH:mm" 
                                value={dateForm.start}
                                onChange={setStart}
                            />
                            <s.WaveText>~</s.WaveText>
                            <Datetime locale="ko" dateFormat="YYYY-MM-DD" timeFormat="HH:mm" 
                                value={dateForm.end}
                                onChange={setEnd}
                            />
                            <s.DateBtn onClick={searchData}>검색</s.DateBtn>
                        </s.DatetimeDiv>
                        </s.HeadLeftArea>
                        <s.BtnDiv>
                            <s.DonwFiltBtnWrapper>
                                <s.DownFileBtn onClick={(e) => window.open('https://www.notion.so/1-fe80cf03d91a4c25ba64816e2d2745c6', 'dataHub-Manual', 'width=1500,height=1000,left=' + (window.screenLeft + (document.body.clientWidth / 2) - (2000 / 2)) + ',top=' + (window.screenTop + (document.body.clientHeight / 2) - (1000 / 2)) + ',location=no,status=yes,scrollbars=yes')}>내 데이터 만들래요!</s.DownFileBtn>
                                <s.DownFileBtn last onClick={downloadData}>현재 데이터 다운로드</s.DownFileBtn>
                            </s.DonwFiltBtnWrapper>
                            <s.TextDiv>
                                <s.CntText>총 {dataLength}개의 데이터</s.CntText>
                                <s.SubText>* 그래프에는 최대 40개의 데이터만 표현됩니다.</s.SubText>
                            </s.TextDiv>
                        </s.BtnDiv>
                    </s.HeadArea>
                    <Line
                        data={{
                            labels: lineState.lineTime,
                            datasets: [
                                {
                                    label: nowState,
                                    fill: false,
                                    lineTension: 0.2,
                                    backgroundColor: 'rgb(0,0,0,0.2)',
                                    borderColor: lineColor,
                                    borderWidth: 2,
                                    data: lineState.lineValue,

                                }
                            ]
                        }}
                        options={{
                            legend: {
                                display: true,
                                position: 'right'
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        max: sensorValue.maxValue,
                                        min: sensorValue.minValue,
                                    }
                                }]
                            }
                        }}
                    />
                    <s.DivBtn>
                        <s.DataBtn onClick={() => setNowState('온도')}>내 온도 데이터</s.DataBtn>
                        <s.DataBtn onClick={() => { setNowState('습도') }}>내 습도 데이터</s.DataBtn>
                        <s.DataBtn onClick={() => { setNowState('거리') }}>내 거리 데이터</s.DataBtn>
                        <s.DataBtn last onClick={() => { setNowState('사운드') }}>내 사운드 데이터</s.DataBtn>
                    </s.DivBtn>
                </s.ContentContainer>
            </s.Container>
        </>
    );
};

export default SensorDataPresenter;