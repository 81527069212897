/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-06
 */

import React from "react";
import * as s from "./ClassroomStyled";

const RoomBox = ({ classroom, clickRoom }) => {
  return (
    <s.RoomBox
      onClick={() => clickRoom(classroom.roomId)}
    >
        <s.BoxImage></s.BoxImage>
        <s.BoxTextWrapper>
          <s.TextTitle>
            {classroom.roomName.substr(
              classroom.roomName.indexOf(" ")
            )}
          </s.TextTitle>
          <s.TextSubTitle>
            {classroom.teacherList[0].name}
          </s.TextSubTitle>
        </s.BoxTextWrapper>
    </s.RoomBox>
  );
};

export default RoomBox;
