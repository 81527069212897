/**
 * @author ydoni
 * @date 2021.07.08
 * @description zero project 추가
 */

import React from "react";
import * as s from "./ZeroStyled"
import * as color from "../../util/Style";

const ZeroPresenter = ({
    projectItem
}) => {
    return (
        <>
        <s.OuterContainer>
            <s.HeadVisualWrap>
                <div
                    style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                >
                <s.HeadTextWrap>
                    <s.SubTitle>미래세대와 환경운동</s.SubTitle>
                    <s.MainTitle>
                        <s.MainStrong>
                            제로 &nbsp;
                        </s.MainStrong>
                         프로젝트
                    </s.MainTitle>
                </s.HeadTextWrap>
                </div>
            </s.HeadVisualWrap>
        
            <s.ContentWrap>
                <s.Inner>
                    <s.Title>제로 프로젝트</s.Title>
                    <s.InfoDescription>
                    환경오염으로 인한 기후문제는 더이상 먼 미래의, 나와는 무관한 일이 아닙니다. <br/>
                    제로 프로젝트는 미래세대가 인공지능 기술을 배우고, 이를 활용해 직접 환경운동을 진행합니다.
                    </s.InfoDescription>
    
                    <s.ItemContainer>
                        {
                            projectItem.map((item, index) =>
                                <s.Item index={index}>
                                    <s.ItemImage><img src={item.src} style={{width:"40%"}}/></s.ItemImage>
                                    {item.title}
                                </s.Item>
                            )
                        }
                    </s.ItemContainer>
                </s.Inner>
            </s.ContentWrap>

            <s.ContentWrap color={color.GrayBg}>
                <s.ProjectWrap>
                    <s.LeftContainer>
                        <img
                            src ={"https://cdn.maiclass.com/maiclass/images/intro/zero/zero.png"}
                            style={{
                                width: "30%"
                            }}
                        />
                        <s.Title>프로젝트 진행 주체</s.Title>
                    </s.LeftContainer>
                    <s.LeftContainer>
                        <img
                            src={"https://cdn.maiclass.com/maiclass/images/intro/zero/zero-project.png"}
                            style={{
                                width: "100%"
                            }}
                        />
                    </s.LeftContainer>
                </s.ProjectWrap>
            </s.ContentWrap>

            <s.ContentWrap color={color.LightTheme}>
                <s.Inner>
                    <s.Title orange>리워드 프로그램</s.Title>
                    <img
                        src={"https://cdn.maiclass.com/maiclass/images/intro/zero/zero-program.png"}
                    />
                </s.Inner>
            </s.ContentWrap>
            
            

        </s.OuterContainer>
        </>
    );
};

export default ZeroPresenter;