import React, { useState } from "react";
import HowtoPresenter from "./HowtoPresenter";
import { contactApi } from "../../api/api-contact";

import * as Yup from "yup";

const HowtoContainer = (props) => {
  const [contactForm, setContactForm] = useState({
    schoolName: "",
    chargerName: "",
    phoneNum: "",
    emailAddress: "",
  });

  const [inputValidArr, setInputValidArr] = useState({
    schoolValid: true,
    chargerValid: true,
    phoneValid: true,
    emailValid: true,
  });

  const [schoolError, setSchoolError] = useState("");
  const [chargerError, setChargerError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const replaceNotInt = /[0-9]/gi;
  const replaceChar = /[^~!@\#$%^&*\()\-=+_'\;<>\/.\`:\"\\,\[\]?|{}]/gi;

  const onChangeForm = (e) => {
    if (e.target.name === "school") {
      if (e.target.value.match(replaceChar) === null) {
        e.target.value = "";
      } else if (
        e.target.value.match(replaceChar).length > 0
      ) {
        e.target.value = e.target.value
          .match(replaceChar)
          .join("");
      }
      setContactForm({
        ...contactForm,
        schoolName: e.target.value,
      });
    } else if (e.target.name === "charger") {
      if (e.target.value.match(replaceChar) === null) {
        e.target.value = "";
      } else if (
        e.target.value.match(replaceChar).length > 0
      ) {
        e.target.value = e.target.value
          .match(replaceChar)
          .join("");
      }
      setContactForm({
        ...contactForm,
        chargerName: e.target.value,
      });
    } else if (e.target.name === "phone") {
      if (e.target.value.match(replaceNotInt) === null) {
        e.target.value = "";
      } else if (
        e.target.value.match(replaceNotInt).length > 0
      ) {
        e.target.value = e.target.value
          .match(replaceNotInt)
          .join("");
      }
      setContactForm({
        ...contactForm,
        phoneNum: e.target.value,
      });
    } else if (e.target.name === "email") {
      setContactForm({
        ...contactForm,
        emailAddress: e.target.value,
      });
    }
  };

  const onPostContact = async () => {
    const sv = await schoolSchema.isValid({
      schoolName: contactForm.schoolName,
    });
    const cv = await chargerSchema.isValid({
      chargerName: contactForm.chargerName,
    });
    const pv = await phoneSchema.isValid({
      phoneNum: contactForm.phoneNum,
    });
    const ev = await emailSchema.isValid({
      emailAddress: contactForm.emailAddress,
    });

    setInputValidArr({
      schoolValid: sv,
      chargerValid: cv,
      phoneValid: pv,
      emailValid: ev,
    });

    formSchema
      .validate(contactForm, { abortEarly: false })
      .catch(function (err) {
        err.inner.forEach((data) => {
          if (data.path === "schoolName") {
            setSchoolError(data.errors);
          } else if (data.path === "chargerName") {
            setChargerError(data.errors);
          } else if (data.path === "phoneNum") {
            setPhoneError(data.errors);
          } else if (data.path === "emailAddress") {
            setEmailError(data.errors);
          }
        });
      });

    let registerResult = null;
    try {
      if (sv && cv && pv && ev) {
        registerResult = await contactApi.registerContact(
          contactForm
        );
        window.location.reload(true);
        alert(
          "성공적으로 문의가 완료되었습니다! 빠른 기한 내에 연락드리도록 하겠습니다."
        );
      } else {
        throw new Error("data not valid");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formSchema = Yup.object().shape({
    schoolName: Yup.string().required(
      "* 학교명은 필수 항목입니다."
    ),
    chargerName: Yup.string().required(
      "* 담당자명은 필수 항목입니다."
    ),
    phoneNum: Yup.string()
      .matches(
        phoneRegExp,
        "* - 을 제외한 숫자만 입력가능 합니다."
      )
      .min(9, "* 전화번호는 9자리 이상입니다.")
      .required("* 전화번호는 필수 항목입니다."),
    emailAddress: Yup.string()
      .email("* 유효하지 않은 이메일 형식입니다.")
      .required("* 이메일은 필수 항목입니다."),
  });

  const schoolSchema = Yup.object().shape({
    schoolName: Yup.string().required(
      "* 학교명은 필수 항목입니다."
    ),
  });

  const chargerSchema = Yup.object().shape({
    chargerName: Yup.string().required(
      "* 담당자명은 필수 항목입니다."
    ),
  });

  const phoneSchema = Yup.object().shape({
    phoneNum: Yup.string()
      .matches(
        phoneRegExp,
        "* - 을 제외한 숫자만 입력가능 합니다."
      )
      .min(9, "* 전화번호는 9자리 이상입니다.")
      .required("* 전화번호는 필수 항목입니다."),
  });

  const emailSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .email("* 유효하지 않은 이메일 형식입니다.")
      .required("* 이메일은 필수 항목입니다."),
  });

  return (
    <HowtoPresenter
      onPostContact={onPostContact}
      onChangeForm={onChangeForm}
      inputValidArr={inputValidArr}
      schoolError={schoolError}
      chargerError={chargerError}
      phoneError={phoneError}
      emailError={emailError}
    ></HowtoPresenter>
  );
};

export default HowtoContainer;
