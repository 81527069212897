import React, { useState, useEffect } from "react";
import * as s from "./AddProjectStyled";
import Select from 'react-select';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const UrlModal = ({ addUrl, closeAddUrl }) => {
    const [manualOn, setManualOn] = useState(false);
    const [hoveringBool, setHoveringBool] = useState(false);
    const [url, setUrl] = useState("");
    const [urlType, setUrlType] = useState("");

    const onMouseEnterManual = () => {
        setHoveringBool(true);
    };

    const onMouseLeaveManual = () => {
        setHoveringBool(false);
    };

    const onClickWindow = () => {
        if (!hoveringBool && manualOn) {
            setManualOn(false);
            setHoveringBool(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", onClickWindow);

        return () => {
            document.removeEventListener("click", onClickWindow);
        };
    });

    const type = [
        { value: 'entry', label: '엔트리' },
        { value: 'scratch', label: '스크래치' },
    ]

    return (
        <>
            <s.UrlPopupBox
                onMouseEnter={onMouseEnterManual}
                onMouseLeave={onMouseLeaveManual}
            >
                <s.UrlPopupWrapper>
                    <s.UrlTypeSection>
                        <s.UrlTypeText>
                            유형
                        </s.UrlTypeText>
                        <s.UrlTypeSelect>
                            <Select
                                className="type-select"
                                value={urlType}
                                onChange={setUrlType}
                                isClearable={true}
                                isSearchable={true}
                                options={type}
                            />
                        </s.UrlTypeSelect>
                        <s.URLCloseButton
                            onClick={() => closeAddUrl()}
                        >
                            <AiOutlineCloseCircle size={24} />
                        </s.URLCloseButton>
                    </s.UrlTypeSection>
                    <s.UrlUrlText>
                        URL
                    </s.UrlUrlText>
                    <s.UrlInputBox
                        placeholder={"URL을 입력하세요."}
                        value={url}
                        onChange={(e) =>
                            setUrl(e.target.value)
                        }
                    />
                    <s.UrlSubmitRow>
                        <s.UrlSubmitButton
                            onClick={() => {
                                console.log(urlType["value"]);
                                if (urlType["value"] === undefined) {
                                    alert("유형을 선택해주세요!");
                                } else {
                                    addUrl(url, urlType["value"]);
                                }
                            }
                            }
                        >
                            등록하기
                        </s.UrlSubmitButton>
                    </s.UrlSubmitRow>
                </s.UrlPopupWrapper>
            </s.UrlPopupBox>
        </>
    );
};

export default UrlModal;
