/**
 * @author hanbin
 * @email
 * @create date 2020-10-26
 * @modify date 2020-10-27
 * @desc Curriculum 리스트 페이지
 */

import React, { useEffect, useState } from "react";
import { curriculumApi } from "../../../api/api-curriculum";
import CurriculumContentPresenter from "./CurriculumContentPresenter";

const CurriculumContentContainer = ({ history, match }) => {
  const [lectureList, setLectureList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [stageText, setStageText] = useState("");
  const [step, setStep] = useState(1);
  const [lectureId, setLectureId] = useState(0);
  const [materialId, setMaterialId] = useState(0);
  const [material, setMaterial] = useState({});
  const [lectureTitle, setLectureTitle] = useState("");

  const [attachmentList, setAttachmentList] = useState({
    pdfList: [],
    htmlList: [],
    pyList: [],
    entryList: [],
    scratchList: [],
    imgList: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const convertCurriculamDataToStageText = (grade, number, level) => {
    const gradeStage = grade;
    const semesterStage = number;
    const schoolStage =
      level === "초" ? "초등학교" : level === "중" ? "중학교" : "고등학교";
    return schoolStage + " " + gradeStage + "학년 " + semesterStage + "학기";
  };

  const goToStep2 = () => {
    setStep(2);
  };

  const goToStep1 = () => {
    setStep(1);
  };

  const goToStep3 = () => {
    setStep(3);
  };

  const fetchData = async () => {
    let res = null;
    let resultData = null;
    setIsLoading(true);
    //step 1
    if (step === 1) {
      try {
        res = await curriculumApi.getLectureList(match.params.curriculumId);
      } catch (e) {
        console.error(e);
      } finally {
        resultData = res.data.data;
        setLectureList(
          //lectureNo 기준으로 정렬
          resultData.lectures.sort((a, b) => {
            return a.lectureNo - b.lectureNo;
          })
        );
        setStageText(
          resultData.fullCurriculum
        );
        setIsLoading(false);
      }
    } else if (step === 2) {
      // step 2
      try {
        res = await curriculumApi.getMaterialList(lectureId);
      } catch (e) {
        console.error(e);
      } finally {
        resultData = res.data.data;
        setLectureTitle(resultData.lectureNo + ". " + resultData.lectureTitle);
        setMaterialList(
          //lectureNo 기준으로 정렬
          resultData.materialList.sort((a, b) => {
            return a.materialNo - b.materialNo;
          })
        );
        setIsLoading(false);
      }
    } else {
      // step 3
        try {
          res = await curriculumApi.getMaterialDetail(materialId);
        } catch (e) {
          // console.error(e);
        } finally {
          if (res === null) {
            setStep(4);
            setIsLoading(false);
          } else {
            console.log(res);
            resultData = res.data.data;
            let pdfs = [];
            let pys = [];
            let htmls = [];
            let imgs = [];
            setMaterial(resultData);
            for (let attachment of resultData.attachments) {
              if (attachment.contentType === "application/pdf") {
                pdfs.push(attachment);
              } else if (attachment.contentType === "text/x-python-script" || attachment.contentType === "text/plain") {
                pys.push(attachment);
              } else if (attachment.contentType === "text/html") {
                htmls.push(attachment);
              } else if (attachment.contentType === "image/png" || attachment.contentType === "image/gif" || attachment.contentType === "image/jpeg") {
                imgs.push(attachment);
              }
            }
            let scratchs = [];
            let entrys = [];
            for (let url of resultData.materialUrls) {
              if (url.type === "scratch") {
                scratchs.push(url);
              } else if (url.type === "entry") {
                entrys.push(url);
              }
            }
            setAttachmentList({
              pdfList: pdfs.sort((a,b) => a.id - b.id),
              htmlList: htmls.sort((a,b) => a.id - b.id),
              pyList: pys.sort((a,b) => a.id - b.id),
              entryList: entrys.sort((a,b) => a.id - b.id),
              scratchList: scratchs.sort((a,b) => a.id - b.id),
              imgList: imgs.sort((a,b) => a.id - b.id),
            });
            setIsLoading(false);
          }
        }
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0,0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <CurriculumContentPresenter
      history={history}
      lectureList={lectureList}
      materialList={materialList}
      material={material}
      stageText={stageText}
      step={step}
      lectureTitle={lectureTitle}
      setLectureId={setLectureId}
      setMaterialId={setMaterialId}
      goToStep1={goToStep1}
      goToStep2={goToStep2}
      goToStep3={goToStep3}
      pdfList={attachmentList.pdfList}
      scratchList={attachmentList.scratchList}
      entryList={attachmentList.entryList}
      pyList={attachmentList.pyList}
      imgList={attachmentList.imgList}
      isLoading={isLoading}
    ></CurriculumContentPresenter>
  );
};

export default CurriculumContentContainer;
