/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-05
 */

import React from "react";
import * as s from "./MainpageStyle";

const ContentBox = ({ title, contentList }) => {
  return (
    <s.ContentBox>
      <s.ContentTitle>{title}</s.ContentTitle>
      <s.FlexRowDiv>
        <s.FlexRowHalfText>
          {contentList &&
            contentList.map((content) =>
              content.state === "join"
                ? content.roomName
                : ""
            )}
        </s.FlexRowHalfText>
      </s.FlexRowDiv>
      <s.ListWrapper>
        {contentList &&
          contentList.map((content,index) =>
            content.state !== "join" ? (
              <s.ListItem key={index}>
                <s.ListText>
                  {content.roomName.substr(
                    0,
                    content.roomName.indexOf(" ")
                  )}
                </s.ListText>
                <s.ListText>
                  {content.roomName.substr(
                    content.roomName.indexOf(" ")
                  )}
                </s.ListText>
              </s.ListItem>
            ) : (
              ""
            )
          )}
      </s.ListWrapper>
    </s.ContentBox>
  );
};

export default ContentBox;
