/**
 * @author hong
 * @email
 * @create date 2020-11-16
 * @modify date 2020-11-16
 * @desc api user info
 */
import { loginedApi } from "./api-base";

export const userApi = {
  getUserDetail: () =>
    loginedApi.get("/user/detail"),
};
