/**
 * @author hong
 * @email
 * @create date 2020-11-2
 * @modify date 2020-11-2
 * @desc 수업 자료 뷰 컨테이너
 */

import React, { useState } from "react";
import {
    BoxContainer,
    BoxTitle,
    PythonSection,
    PyMoveButton,
    TitleContainer,
    TitleBtnContainer,
    TitleButton,
    BtnLabel,
} from "./ComponentBoxStyled";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaCode, FaPlay } from 'react-icons/fa';
import ContentsPdf from "./contentsComponents/ContentsPdf";
import ContentsScratch from "./contentsComponents/ContentsScratch";
import ContentsEntry from "./contentsComponents/ContentsEntry";
import ContentsPython from "./contentsComponents/ContentsPython";
import ContentsImg from "./contentsComponents/ContentsImg";

const ComponentBox = ({
    title,
    content,
    pdfList,
    scratchList,
    entryList,
    pyList,
    imgList,
}) => {
    const [pyIndex, setPyIndex] = useState(0);
    const preClick = () => {
        if (pyIndex > 0) {
            setPyIndex(pyIndex - 1);
        }
    };
    const nextClick = () => {
        if (pyIndex < pyList.length - 1) {
            setPyIndex(pyIndex + 1);
        }
    };

    return (
        <BoxContainer>
            <TitleContainer>
            <BoxTitle>{title}</BoxTitle>
            <TitleBtnContainer>
                
            </TitleBtnContainer>
            </TitleContainer>
            <div style={{flex:1}} dangerouslySetInnerHTML={{ __html: content }}>
            </div>
            {
                pdfList.map((pdf) =>
                    <ContentsPdf
                        key={pdf.id}
                        url={pdf.fileUrl}
                    ></ContentsPdf>
                )
            }
            {
                imgList.map((img) =>
                    <ContentsImg
                        key={img.id}
                        url={img.fileUrl}
                    ></ContentsImg>
                )
            }
            {
                scratchList.map((scratch) =>
                    <ContentsScratch
                        key={scratch.id}
                        url={scratch.url}
                        code={scratch.url.replace('embed', 'editor')}
                    ></ContentsScratch>
                )
            }
            {
                entryList.map((entry) =>
                    <ContentsEntry
                        key={entry.id}
                        url={entry.url.replace('api/project/','')}
                        code={entry.url.replace('api/project/iframe', 'ws')}
                    ></ContentsEntry>
                )
            }
            {
                pyList.length > 0 ?
                    <PythonSection>
                        <PyMoveButton
                            onClick={() => preClick()}
                        ><FaArrowAltCircleLeft size={20} /></PyMoveButton>
                        <ContentsPython
                            key={pyList[pyIndex].id}
                            fileName={pyList[pyIndex].fileName}
                            fileUrl={pyList[pyIndex].fileUrl}
                        ></ContentsPython>
                        <PyMoveButton
                            onClick={() => nextClick()}
                        ><FaArrowAltCircleRight size={20} /></PyMoveButton>
                    </PythonSection>
                    : null
            }
        </BoxContainer>
    );
};

export default ComponentBox;
