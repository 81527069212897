import React from "react";
import * as s from "./AddProjectStyled";
import { HiOutlineChevronRight } from "react-icons/hi";
import { FiUpload, FiLink2 } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";
import NotLoginedSidebar from "../../sections/NotLoginedSidebar";
import LoginedSidebar from "../../sections/LoginedSidebar";
import PythonEditor from "./pythonEditor/PythonEditor";
import UrlModal from "./UrlModal";
import {tokenCheck} from "../../../util/util";


const AddProjectPresenter = ({
  history,
  isPythonOn,
  isUrlOn,
  pythonFile,
  attachFile,
  setProjectTitle,
  setProjectContent,
  addPythonFile,
  deletePythonFile,
  setAttachFile,
  deleteAttachFile,
  onClickPython,
  onClickUrl,
  onSubmit,
  addUrl,
  urlList,
  deleteUrl,
}) => {
  return (
    <div>
      {tokenCheck() ? (
        <LoginedSidebar />
      ) : (
        <NotLoginedSidebar />
      )}
      <s.Container>
        <s.ContentContainer>
          <s.TitleWrapper>
            <s.PrevTitle
              onClick={() => history.push("/project")}
            >
              프로젝트
            </s.PrevTitle>
            <HiOutlineChevronRight />
            <s.CurTitle>추가</s.CurTitle>
          </s.TitleWrapper>
          <s.AddContainer>
            <s.AddContentWrapper>
              <s.TitleText>제목</s.TitleText>
              <s.TitleInputBox
                placeholder={"제목을 입력하세요."}
                onChange={(e) =>
                  setProjectTitle(e.target.value)
                }
              ></s.TitleInputBox>
            </s.AddContentWrapper>
            <s.AddContentWrapper>
              <s.TitleText>내용</s.TitleText>
              <s.ContentTextArea
                placeholder={"글을 작성하세요."}
                onChange={(e) =>
                  setProjectContent(e.target.value)
                }
              ></s.ContentTextArea>
            </s.AddContentWrapper>
          </s.AddContainer>
          {
            isUrlOn?
              <UrlModal
                addUrl={addUrl}
                closeAddUrl={onClickUrl}
              />
            : null
          }
          <s.FlexRowBetweenDiv>
            <s.FlexRowGapDiv>
              <s.URLInputWrapper>
                <s.URLInputLabel for="url">
                  URL 등록 <FiLink2 size={32} />
                </s.URLInputLabel>
                <s.URLInputButton
                  id="url"
                  onClick={()=>onClickUrl()}
                />
              </s.URLInputWrapper>

              {Array.from(urlList).map((item, index) => (
                <s.UploadedFileBlock
                  key={index}
                >
                  {item.type}
                  <TiDelete
                    size={24}
                    style={{
                      position: "absolute",
                      transform:
                        "translate3d(-0.2em, -1.2em,0)",
                    }}
                    onClick={() => deleteUrl(index)}
                  />
                </s.UploadedFileBlock>
              ))}
            </s.FlexRowGapDiv>
          </s.FlexRowBetweenDiv>
          <s.FlexRowBetweenDiv>
            <s.FlexRowGapDiv>
              <s.FileInputWrapper>
                <s.FileInputLabel for="file">
                  파일 첨부 <FiUpload size={32} />
                </s.FileInputLabel>
                <s.FileInput
                  type="file"
                  id="file"
                  multiple
                  onChange={(e) => {
                    const newFiles = e.target.files;
                    setAttachFile((p) =>
                      p.concat(...newFiles)
                    );
                  }}
                />
              </s.FileInputWrapper>

              {Array.from(attachFile).map((item, index) => (
                <s.UploadedFileBlock>
                  {item.name}
                  <TiDelete
                    size={24}
                    style={{
                      position: "absolute",
                      transform:
                        "translate3d(-0.2em, -1.2em,0)",
                    }}
                    onClick={() => deleteAttachFile(index)}
                  />
                </s.UploadedFileBlock>
              ))}
            </s.FlexRowGapDiv>
            <s.SubmitButton onClick={onSubmit}>
              등록하기
            </s.SubmitButton>
          </s.FlexRowBetweenDiv>
          <s.FlexRowGapDiv
            style={{
              marginTop: "5%",
              marginBottom: "5%",
              width: "100%",
            }}
          >
            <s.PythonToggleButton onClick={onClickPython}>
              파이썬 연습장 +
            </s.PythonToggleButton>
            {pythonFile &&
              pythonFile.map((file, index) => (
                <s.UploadedFileBlock key={file.id}>
                  {file.name}
                  <TiDelete
                    size={24}
                    style={{
                      position: "absolute",
                      transform:
                        "translate3d(-0.1em, -1.3em,0)",
                    }}
                    onClick={() => deletePythonFile(index)}
                  />
                </s.UploadedFileBlock>
              ))}
          </s.FlexRowGapDiv>

          {isPythonOn ? (
            <PythonEditor
              addPythonFile={addPythonFile}
            />
          ) : (
            ""
          )}
        </s.ContentContainer>
      </s.Container>
    </div>
  );
};

export default AddProjectPresenter;
