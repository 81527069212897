import styled from "styled-components";

const OuterContainer = styled.div`
    width: 100%;
`;

// 1080px 기준은 Container로
const Container = styled.div`
    width: 1080px;
    margin: 0 auto;
`;

const Title = styled.h1`
    font-size: 25px;
`;

const HeadVisualWrap = styled.div`
    background: url("https://cdn.maiclass.com/maiclass/images/intro/howtostart/start-head-image.jpg") no-repeat center;
    height: 300px;
    /* overflow: hidden; */
    position: relative;
    background-size: cover;
`
const HeadTextWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 1080px;
    transform: translateX(-50%);
    box-sizing: border-box;
    z-index: 30;
    text-align: right;
`

const HeadTextContent = styled.p`
    font-size: 30px;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    line-height: 1.6;
    letter-spacing: -0.25px;
`

const MainTitle = styled.h1`
  font-weight: bold;
  font-size: 46px;
  color: #fff;
  line-height: 1.2em;
`;

const SubTitle = styled(MainTitle)`
  font-weight: 400;
  font-size: 24px;
  text-align: right;
  margin-bottom: 10px;
`;

const MainStrong = styled.strong`
  font-weight: bold;
  color: #F0B828;
`;
///////////////
//  Content  //
///////////////

const ContentWrap = styled.div`
    padding-top: 60px;
`;

const ContentSection = styled.section`

`;

const InnerSection = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    box-sizing: border-box;
    display: block;
    justify-content: space-between;
`;

////////
//Info//
////////

const InfoSection = styled.div`
    width: 1080px;
`;

const ListInfo = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const InfoBundle = styled.li`
    width: 50%;

`;

const InfoLogo = styled.div`
    width: 65px;
    height: 65px;
    background: url(${(props) => props.bgUrl}) no-repeat center;
    background-size: contain;
`;
const InfoTitle = styled.em`
    margin-top: 23px;
    color: #F0B828;
    display: block;
    margin-top: 33px;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.75;
`;  

const InfoDescription = styled.p`
    display: block;
    margin-top: 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
    color: #666;
`;

/////////////
// Contact //
/////////////

const ContactSection = styled.div`
    width: 1080px;
`;

const ContactBox = styled.div`
    border-radius: 10px;
    background-color: #fefbf4;
    margin-top: 100px;
    border-radius: 12px;
`;

const InnerBox = styled.div`
    padding: 60px 0;
`;

const ContactTitleBox = styled.div`
    width: 50%;
    /* text-align: center; */
    float: left;
    margin-top: 40px;
`;

const ContactIcon = styled.div`
    width: 140px;
    height: 140px;
    background: url(${(props) => props.bgUrl}) no-repeat center;
    background-size: contain;
    margin: 0 auto;
`;

const ContactDescription = styled.p`
    /* float:left; */
    line-height: 1.4em;
    text-align: center;
    font-size: 24px;
    padding: 30px 0;
    margin: 0 auto;
`;

const InputFormWrapper = styled.div`
    display: inline-block;
    width: 450px;
`;
const InputForm = styled.div`

`;

const InputBundle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const InputLabel = styled.label`
    font-size: 15px;
    margin: 10px 0;
`;  

const InputText = styled.input`
    border-radius: 10px;
    border: none;
    padding : 5px 10px;
    width: 330px;
    height: 25px;
    font-size: 12px;

    ::placeholder {
        font-weight: lighter;
        color: #CCCCCC;
    }
`;

const InputFeedBack = styled.div`
    color: #c0392b;
    font-size: 12px;
    position: absolute;
    margin-left: 103px;
    margin-top: 44px;
`;

const SubmitBundle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const InputDescription = styled.p`
    margin-top: 10px;
    font-size: 12px;
    color: #CCCCCC;
`;

const InputSubmit = styled.button`
    color: #F0B828;
    padding: 10px 0;
`;

export {
    Container,
    OuterContainer,
    //Head
    HeadVisualWrap,
    HeadTextWrap,
    HeadTextContent,
    MainTitle,
    SubTitle,
    MainStrong,
    Title,
    //Content
    ContentWrap,
    ContentSection,
    InnerSection,
        //Info
    InfoSection,
    ListInfo,
    InfoBundle,
    InfoLogo,
    InfoTitle,
    InfoDescription,
        //Contact
    ContactSection,
    ContactBox,
    ContactTitleBox,
    InnerBox,
    ContactIcon,
    ContactDescription,
    InputFormWrapper,
    InputForm,
    InputBundle,
    InputLabel,
    InputText,
    InputFeedBack,
    SubmitBundle,
    InputDescription,
    InputSubmit
};