import React from "react";
import Header from "../sections/Header";
import Introduction from "../intro/Introduction";

import styled from "styled-components";

const Container = styled.div`
  /* position: relative;
  overflow: hidden; */
`;

function HomeIndex() {
  return (
    <Container>
      {/* 헤더 사진 */}
      <Header />
        
      <Introduction />

      {/* 푸터 */}
    </Container>
  );
}
export default HomeIndex;
