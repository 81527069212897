import styled from "styled-components";

const OuterContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
`;

const Box = styled.div`
    width: 700px;
    height: 400px;
`;

const FirstText = styled.div`
    font-size: 36px;
    margin: 38px 200px 40px 40px;

`;

const SecondText = styled.div`
    font-size: 16px;
    margin-left: 40px;
    color: #000000;
    display: ${(props) =>
        props.error ? "none" : "block"};
`;

const ErrorText = styled.div`
    font-size: 16px;
    margin-left: 40px;
    margin-top: 15px;
    color: red;
    display: ${(props) =>
        props.error ? "block" : "none"};
`;

const InputEmail = styled.input`
    width: 472px;
    height: 45px;
    border: 1px solid #C9C9C9;
    outline: none;
    border-radius: 8px;
    line-height: 2.5rem;
    font-size: 18px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 40px;
    margin-top: 20px;
`;

const InputPassword = styled.input`
    width: 472px;
    height: 45px;
    border: 1px solid #C9C9C9;
    outline: none;
    border-radius: 8px;
    line-height: 2.5rem;
    font-size: 18px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 40px;
    margin-top: 10px;
`;

const LoginButton = styled.button`
    width: 496px;
    height: 50px;
    border: 1px solid #F0B828;
    border-radius: 8px;
    outline: none;
    background-color: #F0B828;
    color: #FFFFFF;
    font-weight: bold;
    margin-left: 40px;
    font-size: 20px;
    margin-top: 15px;
    cursor: pointer;
`;

const LastText = styled.div`
    font-size: 16px;
    margin-left: 385px;
    margin-top: 40px;
`;

const IconItem = styled.li`
    width: 350px;
    height: 225px;
    background: url(${(props) => props.bgUrl}) no-repeat
    center;
    background-size: contain;
    position: absolute;
`;

export{
    OuterContainer,
    Box,
    FirstText,
    SecondText,
    ErrorText,
    InputEmail,
    InputPassword,
    LoginButton,
    LastText,
    IconItem
};