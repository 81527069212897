/**
 * @author ydoni
 * @date 2021.2.24
 * @description style 수정
 */

import styled from "styled-components";
import "react-datetime/css/react-datetime.css";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const ContentContainer = styled.section`
  padding: 3% 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FirstTitle = styled.h3`
  font-size: 30px;
  padding: 20px;
  display:inline-block;
`;

export const DataChart = styled.div`
  width: 800px;
  height: 500px;
`;

export const DivBtn = styled.div`
  margin-top: 80px;
`;

export const DataBtn = styled.button`
  margin-left: 36px;
  margin-right: 36px;
  width: 190px;
  height: 44px;
  background-color: #F0B828;
  color: white;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
`;
export const TextDiv = styled.div`
  margin-top: 20px;
  /* width: 90%; */
  text-align: end;
`;

export const CntText = styled.div`
  font-size: 20px;
  text-align: end;
`;
export const SubText = styled.div`
  font-size: 12px;
  text-align: end;
  color:red;
  padding-top: 10px;
`;

export const BtnDiv = styled.div`
  display:inline-block;
  width: 45%;
  vertical-align: top;
`;

export const ManualBtn = styled.button`
  width: 220px;
  height: 44px;
  margin-right: 20px;
  background-color: #F0B828;
  color: white;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
`;

export const DonwFiltBtnWrapper = styled.div`
  text-align: end;
`;

export const DownFileBtn = styled.button`
  padding: 15px;
  background-color: #F0B828;
  color: white;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  margin-right: ${(props) => props.last ? 0 : "20px"};
`;

export const DatetimeDiv = styled.div`
  display:inline-block;

  .rdt {
    display:inline-block;
    margin: 1em;

    input {
      font-size: 15px;
      padding: .3em .0em .3em .0em; /* 원하는 여백 설정, 상하단 여백으로 높이를 조절 */
      text-align: center;
    }
  }
`;

export const WaveText = styled.h4`
  font-size: 20px;
  display:inline-block;
`;

export const DateBtn = styled.button`
  width: 62px;
  height: 35px;
  background-color: #FFF8DC;
  color: #F0B828;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  display:inline-block;
  cursor: pointer;
`;

export const HeadArea = styled.div`
  display:inline-block;
`;

export const HeadLeftArea = styled.div`
  display:inline-block;
  width: 55%;
`;