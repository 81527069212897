/**
 * @author hong
 * @email
 * @create date 2020-11-17
 * @modify date 2020-11-17
 * @desc 커리큘럼리스트 presenter
 */

import React from "react";
import LoginedSidebar from "../../sections/LoginedSidebar";
import NotLoginedSidebar from "../../sections/NotLoginedSidebar";
import ContentHeader from "./ContentHeader";
import ContentBox from "./ContentBox";
import ComponentBox from "../../contents/ComponentBox";
import Loader from "../../Loader";
import {
  Container,
  ContentContainer,
  Message,
} from "./ClassroomCurriculumPresenterStyled";
import {tokenCheck} from "../../../util/util";

const ClassroomCurriculumPresenter = ({
  history,
  curriculumList,
  lectureList,
  materialList,
  material,
  stageText,
  step,
  lectureTitle,
  setCurriculumId,
  setLectureId,
  setMaterialId,
  goToStep0,
  goToStep1,
  goToStep2,
  goToStep3,
  pdfList,
  scratchList,
  entryList,
  pyList,
  imgList,
  isLoading,
}) => {
  return (
    <>
      {tokenCheck() ? (<LoginedSidebar />) : (<NotLoginedSidebar />)}
      {
        isLoading ?
          <Loader />
          : <Container>
            <ContentHeader
              history={history}
              step={step} // 1 : 학기 단원 목록, 2: 단원 강의 자료 목록, 3: 강의 자료
              stageText={stageText}
              goToStep0={goToStep0}
              goToStep1={goToStep1}
              goToStep2={goToStep2}
              goToStep3={goToStep3}
              title={lectureTitle}
            />
            <ContentContainer>
              {step === 0
                ? curriculumList.map((content) => (
                  <ContentBox
                    key={content.curriculumId}
                    title={content.fullCurriculum}

                    description={""}
                    step={step} // 1 : 학기 단원  목록, 2: 단원 강의 자료 목록, 3: 강의 자료
                    onClick={goToStep1}
                    setCurriculumId={()=>
                      setCurriculumId(content.curriculumId)
                    }
                    setLectureId={() =>
                      setLectureId(content.lectureId)
                    }
                    setMaterialId={() =>
                      setMaterialId(content.materialId)
                    }
                  />
                ))
                : step === 1
                ? lectureList.map((content) => (
                  <ContentBox
                    key={content.lectureNo}
                    title={
                      content.lectureNo +
                      ". " +
                      content.lectureTitle
                    }
                    description={content.fieldTitle}
                    step={step} // 1 : 학기 단원 목록, 2: 단원 강의 자료 목록, 3: 강의 자료
                    onClick={goToStep2}
                    setLectureId={() =>
                      setLectureId(content.lectureId)
                    }
                    setMaterialId={() =>
                      setMaterialId(content.materialId)
                    }
                  />
                ))
                : step === 2
                  ? materialList.map((content) => (
                    <ContentBox
                      key={content.materialId}
                      title={
                        content.materialNo +
                        ". " +
                        content.materialTitle
                      }
                      description={content.materialDescription}
                      step={step} // 1 : 학기 단원 목록, 2: 단원 강의 자료 목록, 3: 강의 자료
                      onClick={goToStep3}
                      setLectureId={() =>
                        setLectureId(content.lectureId)
                      }
                      setMaterialId={() =>
                        setMaterialId(content.materialId)
                      }
                    />
                  ))
                  : step === 3
                    ? <ComponentBox
                      title={material.materialNo + ". " + material.materialTitle}
                      content={material.materialContent}
                      attachments={material.attachments}
                      urls={material.materialUrls}
                      pdfList={pdfList}
                      scratchList={scratchList}
                      entryList={entryList}
                      pyList={pyList}
                      imgList={imgList}
                    ></ComponentBox>
                    :
                    <Message>
                      데이터가 존재하지 않습니다.
              </Message>
              }
            </ContentContainer>
          </Container>
      }
    </>
  );
};

export default ClassroomCurriculumPresenter;
