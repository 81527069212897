import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 1400px;
  margin: 0 auto;
`;

const LoginedSidebar = styled.nav`
  width: 130px;
  height: 100%;
  background-color: white;
  border: 0.75px solid #ededed;
  color: #303030;
  position: fixed;
  z-index: 990;
`;

const AdminInfo = styled.div`
  width: 90%;
  text-align: center;
  margin: 50px auto 26px;
`;

export const ProfileText = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-top: 20%;
`;

export const SubText = styled.div`
  font-size: 16px;
  color: #5e5e5e;
  font-weight: 300;
  margin-top: 20%;
`;

export const RoomSubList = styled.ul`
  width: 100%;
  background-color: white;
  display: ${(props) => (props.current ? "block" : "none")};
`;

export const RoomSubItem = styled.li`
  color: ${(props) =>
    props.current ? "#F0B828" : "#CCCCCC"};

  &:hover {
    color: "#F0B828";
  }
`;

export const SubSLink = styled(Link)`
  padding: 25px 0;

  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: ${(props) =>
    props.current ? "600" : "400"};
`;

const List = styled.ul`
  width: 100%;
`;

const Item = styled.li`
  font-size: 16px;
  color: ${(props) => (props.current ? "#fff" : "#303030")};
`;

const SLink = styled(Link)`
  padding: 20px 0;

  background-color: ${(props) =>
    props.current
      ? "rgb(240, 184, 40)"
      : "rgba(240, 184, 40,0.1)"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: ${(props) =>
    props.current ? "500" : "400"};

  &:hover {
    background-color: rgb(240, 184, 40);
  }
`;

const Icon = styled.div`
  background: url(${(props) => props.bgUrl}) no-repeat
    center;
  width: 38px;
  height: 30px;
`;

const Logout = styled.button`
  width: 100%;
  color: #f0b828;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
`;

export {
  Container,
  LoginedSidebar,
  AdminInfo,
  List,
  Item,
  SLink,
  Icon,
  Logout,
};
