import styled from "styled-components";

// popup
export const PopupBox = styled.div`
  position: fixed;
  width: 700px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 40%;
  background-color: #fff;
  border: 2px solid black;
  border-radius: 10px;
  z-index: 30;
`;

export const PopupWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  line-height: 2;
`;

export const UrlText= styled.h3`
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const UrlInputBox = styled.input`
  width: 100%;
  padding: 2%;
  margin-bottom: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  font-size: 18px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
`;

export const SubmitButton = styled.button`
  color: white;
  padding: 1% 2%;
  width: 20%;
  font-size: 18px;
  font-weight: 600;
  background-color: #f0b828;
`;

export const SubmitRow = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: right;
`;

export const URLCloseButton = styled.button`
  width: 5px;
  height: 5px;
  padding: 0;
  margin-left: 600px;
  margin-top: 20px;
  clip: rect(0, 0, 0, 0);
  border: 0;
    outline: none;
`;