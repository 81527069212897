/*********************************
//*** @Author : yeondo ***
//*** @Date : 2020.09.15 ***
//*** @Title : CurriculumPresenter ***
*********************************/

import React from "react";
import {
  OuterContainer,
  HeadVisualWrap,
  HeadTextWrap,
  SubTitle,
  MainStrong,
  MainTitle,
  ContentWrap,
  BigInnerContent,
  Elementary,
  IconBox,
  IconItem,
  SchoolTitle,
  TitleBox,
  SchoolIcon,
  CurriContents,
  CurriListBox,
  CurriList,
  CurriListItem,
  CurriTitle,
  Middle,
  Content,
  SLink,
  Smalltext,
  AIElementary,
} from "./CurriculumPresenterStyled";
import * as s from "./CurriculumPresenterStyled";

const CurriculumPresenter = () => {
  return (
    <>
      <OuterContainer>
        <HeadVisualWrap>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <HeadTextWrap>
              <SubTitle>
                AI 전문가로 성장하는
              </SubTitle>
              <MainTitle>
                MAICLASS&nbsp;
                <MainStrong>AI 교육</MainStrong>
              </MainTitle>
            </HeadTextWrap>
          </div>
        </HeadVisualWrap>

        {/* 커리큘럼 내용 */}
        <Content>
          <ContentWrap>

          <s.AIContainer>
              {/* University */}
              <AIElementary>
                <TitleBox>
                  {/* <SchoolIcon
                    bgUrl={
                      "https://cdn.myzzym.com/maiclass/images/intro/ar/ar-head-image.jpg"
                    }
                  ></SchoolIcon> */}
                  <SchoolTitle> University </SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${41}`}>
                          1. EnergyAI딥러닝
                        </SLink>
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${42}`}>
                          2. AI 프로그래밍
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </AIElementary>
            </s.AIContainer>

            <s.AIContainer>
              {/* career */}
              <AIElementary>
                <TitleBox>
                  {/* <SchoolIcon
                    bgUrl={
                      "https://cdn.myzzym.com/maiclass/images/intro/classes/curri-ai-icon-05.png"
                    }
                  ></SchoolIcon> */}
                  <SchoolTitle> Career </SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${43}`}>
                          1. AI 최적화
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${45}`}>
                          3. AI Classification
                        </SLink>
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${44}`}>
                          2. 의료+AI
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </AIElementary>
            </s.AIContainer>

            <s.AIContainer>
              {/* AI 수학 */}
              <AIElementary>
                <TitleBox>
                  <SchoolIcon
                    bgUrl={
                      "https://cdn.myzzym.com/maiclass/images/intro/classes/curri-ai-icon-05.png"
                    }
                  ></SchoolIcon>
                  <SchoolTitle> AI 수학</SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${25}`}>
                          1. 기 호
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${27}`}>
                          3. 함 수
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${29}`}>
                          5. 확 률
                        </SLink>
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${26}`}>
                          2. 논 리
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${28}`}>
                          4. 미 분
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${30}`}>
                          6. 행렬연산
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </AIElementary>
            </s.AIContainer>

            {/* AI 원리 */}
            <s.AIContainer>
              <AIElementary style={{width: "100%"}}>
                <TitleBox>
                  <SchoolIcon
                    bgUrl={
                      "https://cdn.myzzym.com/maiclass/images/intro/classes/curri-ai-icon-04.png"
                    }
                  ></SchoolIcon>
                  <SchoolTitle> AI 원리</SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${31}`}>
                          <Smalltext>
                            1. 퍼셉트론
                          </Smalltext>
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${33}`}>
                          <Smalltext>
                            3. 신경망구현
                          </Smalltext>
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${35}`}>
                          <Smalltext>
                            5. 역전파구현
                          </Smalltext>
                        </SLink>
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${32}`}>
                          <Smalltext>
                            2. 신경망함수
                          </Smalltext>
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${34}`}>
                          <Smalltext>
                            4. 역전파
                          </Smalltext>
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${36}`}>
                          <Smalltext>
                            6. 딥러닝
                          </Smalltext>
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </AIElementary>
            </s.AIContainer>

            <s.AIContainer>
              {/* 커리큘럼 */}
              <AIElementary>
                <TitleBox>
                  <SchoolIcon
                    bgUrl={
                      "https://cdn.myzzym.com/maiclass/images/intro/classes/curri-tutorial-icon-01.png"}
                  ></SchoolIcon>
                  <SchoolTitle>Lifelong eud.</SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${37}`}>
                        <Smalltext>1. 파이썬</Smalltext>
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${39}`}>
                        <Smalltext>3. 데이터 분석</Smalltext>
                        </SLink>
                      </CurriListItem>
                      {/* <CurriListItem bgColor>
                        <SLink to={`/curriculum/${29}`}>
                          5. 확 률
                        </SLink>
                      </CurriListItem> */}
                    </CurriList>

                    <CurriList>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${38}`}>
                        <Smalltext>2. AI 학습</Smalltext>
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/aiedu/${40}`}>
                        <Smalltext>4. 데이터 라벨링</Smalltext>
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </AIElementary>
            </s.AIContainer>

            
          </ContentWrap>
        </Content>
      </OuterContainer>
    </>
  );
};

export default CurriculumPresenter;
