/**
 * @author hong
 * @email
 * @create date 2020-11-25
 * @modify date 2020-12-15
 * 
 * @author jeongin
 * @email
 * @modify date 2020-12-22
 */

import React, { useState, useEffect } from "react";
import {useLocalStorage} from "../../../util/useLocalStorage";
import ClassroomMeetingRoomPresenter from "./ClassroomMeetingRoomPresenter";
import { teacherCheck } from "../../../util/util";
import { roomApi } from "../../../api/api-room"; 


const ClassroomMeetingRoomContainer = () => {

  const [room, setRoom] = useLocalStorage('room', {});

  const [isModalOn, setIsModalOn] = useState(false);


  const clickRoom = async (roomId) => {

    let result = null;
    try {
      result = await roomApi.getRoomDetail(roomId);
    } catch (e) {
      console.error(e);
    } finally {
      const roomInfo = {
        roomId: result.data.data.roomId,
        meetingUrl: result.data.data.meetingUrl,
        roomName: result.data.data.roomName,
      };
      setRoom(roomInfo);
      localStorage.setItem("room",JSON.stringify(roomInfo));
    }
  }

const addUrl = async(url) => {

    let result = null;

    if(url === ""){
        alert("다시 입력하세요!");
    }
    else{
        try {
            result = await roomApi.putMeetingRoom(room.roomId, url);
            }
            catch(e){
            console.error(e);
            }
            finally{
                if(result.data.success){
                    alert("등록 / 재등록 되었습니다!");
                    clickRoom(room.roomId);
                    onClickModal();
                }
            }
    }  
  }
  const onClickModal = () => {
      setIsModalOn((p) => !p);
    };

  const GotoRoom = () => {
      if(room.meetingUrl){
        window.open(room.meetingUrl, "meetingRoom", "width=1500, height=1000,left="+(window.screenLeft+(document.body.clientWidth/2)-(2000/2))+',top='+(window.screenTop+(document.body.clientHeight/2)-(1000/2))+',location=no,status=yes,scrollbars=yes');  
      }
      else{
        alert("강의실이 존재하지 않습니다.");
      }
    }

  useEffect(() => {
    if(!teacherCheck()){
      GotoRoom();
    };
  }, []); 
  
  return (
    <ClassroomMeetingRoomPresenter
      GotoRoom={GotoRoom}
      isModalOn={isModalOn}
      onClickModal={onClickModal}
      addUrl={addUrl}
    ></ClassroomMeetingRoomPresenter>
  );
};

export default ClassroomMeetingRoomContainer;
