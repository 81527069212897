import styled from "styled-components";

const FooterBox = styled.footer`
  width: 100%;
  height: 165px;
  background-color: #fafafa;
  box-sizing: border-box;
  margin-top: 80px;
  z-index: 995;
  position: relative;
`;

const Container = styled.div`
  width: 1080px;
  margin: 0 auto;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const LogoImage = styled.img`
  width: 85px;
  margin-top: 50px;
`;

const FooterText = styled.p`
  color: #cccccc;
  float: right;
  text-align: right;
  line-height: 1.5em;
  padding-top: 60px;
  font-weight: 400;
  font-size: 14px;
`;

const FooterTextInfo = styled(FooterText)`
  text-align: left;
`;

export {
  FooterBox,
  Container,
  LogoImage,
  FooterText,
  FooterTextInfo,
};
