import styled from "styled-components";
import {ThemeColor, Gray, LightGray, Black} from "../../../util/Style";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const Message = styled.h2`
  padding-top: 5%;
  font-size: 20px;
  font-weight: 600;
`;

export const ClassRoomContainer = styled.div`
  width: 80%;
  margin: 0 auto;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
`;

export const MakeRoom = styled.button`
  width: 48%;
  float: left;
  text-align: center;
  border-radius: 3px;
  border: 1px dashed ${LightGray};
  cursor: pointer;
  padding: 120px 0;
  margin-top: 120px;

  &:nth-child(2){
    float: right;
  }
  &:hover {
    background-color: #fafafa;
    border: 1px solid ${LightGray};
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100px;
  background: url(${(props) => props.bgUrl}) no-repeat center;
  background-size: contain;
  margin: 0 auto;
  
`;

export const ButtonText = styled.p`
  padding-top: 40px;
  font-size: 1.125rem;
  color: ${Black};
`;

export const MakeRoomBtn = styled.button`
  width: 220px;
  height: 44px;
  color: ${ThemeColor};
  font-size: 1rem;
  outline: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${ThemeColor};
  
  &:hover {
    background-color: ${ThemeColor};
    color: #fff;
    font-weight: bold;
  }
`;
