import React, { useEffect, useState } from "react";
import SensorDataPresenter from "./SensorDataPresenter";
import { datahubApi } from "../../api/api-datahub";
import { changeDateAllToFormat, changeDateForSearch } from "../../util/util";

const SensorDataContainer = ({ history }) => {

    const today = new Date();

    const [nowState, setNowState] = useState("온도");

    const [lineState, setLineState] = useState({
        lineTime: '',
        lineValue: '',
    })

    const [lineColor, setLineColor] = useState('rgba(255,0,0)');

    const [sensorValue, setSensorValue] = useState({
        maxValue: 70,
        minValue: 0,
    });

    const [dataLength, setDataLength] = useState(0);

    const [dateForm, setDateForm] = useState({
        start: (new Date()).setDate(today.getDate() - 7),
        end: today,
    });

    const sortAndSliceChartData = (data) => {
        data = data.sort((a,b) => a.time - b.time);
        if(data.length > 40){
            data = data.slice(-40);
        }
        return data;
    }

    const setStart = (start) => {
        setDateForm({
            ...dateForm,
            start: start,
        })
    }

    const setEnd = (end) => {
        setDateForm({
            ...dateForm,
            end: end,
        })
    }

    const downloadData = async () => {
        let res = null;
        let request = {
            start: changeDateForSearch(dateForm.start),
            end:changeDateForSearch(dateForm.end),
        }
        try {
            switch (nowState) {
                case '온도':
                    res = await datahubApi.downloadTemperatureData(request);
                    break;
                case '습도':
                    res = await datahubApi.downloadHumidityData(request);
                    break;
                case '거리':
                    res = await datahubApi.downloadDistanceData(request);
                    break;
                case '사운드':
                    res = await datahubApi.downloadSoundData(request);
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
        } finally {
            if (res !== null) {
                window.location.href = res.data.data;
            }
        }
    }

    const searchData = async () => {
        let res = null;
        let request = {
            start: changeDateForSearch(dateForm.start),
            end:changeDateForSearch(dateForm.end),
        }
        try {
            switch (nowState) {
                case '온도':
                    res = await datahubApi.getTemperatureData(request);
                    setLineColor('rgba(255,0,0)');
                    setSensorValue({
                        maxValue: 50,
                        minValue: 0,
                    });
                    break;
                case '습도':
                    res = await datahubApi.getHumidityData(request);
                    setLineColor('rgba(137, 196, 244, 1)');
                    setSensorValue({
                        maxValue: 90,
                        minValue: 0,
                    });
                    break;
                case '거리':
                    res = await datahubApi.getDistanceData(request);
                    setLineColor('rgba(42, 187, 155, 1)');
                    setSensorValue({
                        maxValue: 4,
                        minValue: 0.02,
                    });
                    break;
                case '사운드':
                    res = await datahubApi.getSoundData(request);
                    setLineColor('rgba(245, 229, 27, 1)');
                    setSensorValue({
                        maxValue: 255,
                        minValue: 0,
                    });
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
        } finally {
            if (res !== null) {
                let time = [];
                let value = [];

                let chartData = sortAndSliceChartData(res.data.list);

                chartData.map(e => {
                    time.push(changeDateAllToFormat(e.time));
                    value.push(e.value);
                });
                setDataLength(res.data.list.length);
                time.reverse();
                value.reverse();

                setLineState({
                    lineTime: time,
                    lineValue: value
                });
            }
        }
    }

    const fetchData = async () => {
        let res = null;
        let request = {
            start: changeDateForSearch(dateForm.start),
            end:changeDateForSearch(dateForm.end),
        }
        try {
            switch (nowState) {
                case '온도':
                    res = await datahubApi.getTemperatureData(request);
                    setLineColor('rgba(255,0,0)');
                    setSensorValue({
                        maxValue: 50,
                        minValue: 0,
                    });
                    break;
                case '습도':
                    res = await datahubApi.getHumidityData(request);
                    setLineColor('rgba(137, 196, 244, 1)');
                    setSensorValue({
                        maxValue: 90,
                        minValue: 0,
                    });
                    break;
                case '거리':
                    res = await datahubApi.getDistanceData(request);
                    setLineColor('rgba(42, 187, 155, 1)');
                    setSensorValue({
                        maxValue: 4,
                        minValue: 0.02,
                    });
                    break;
                case '사운드':
                    res = await datahubApi.getSoundData(request);
                    setLineColor('rgba(245, 229, 27, 1)');
                    setSensorValue({
                        maxValue: 255,
                        minValue: 0,
                    });
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
        } finally {
            if (res !== null) {
                let time = [];
                let value = [];

                let chartData = sortAndSliceChartData(res.data.list);

                chartData.map(e => {
                    time.push(changeDateAllToFormat(e.time));
                    value.push(e.value);
                });
                setDataLength(res.data.list.length);

                time.reverse();
                value.reverse();

                setLineState({
                    lineTime: time,
                    lineValue: value
                });
            }
        }
    }

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nowState]);

    return (
        <SensorDataPresenter
            nowState={nowState}
            setNowState={setNowState}
            lineState={lineState}
            setLineState={setLineState}
            lineColor={lineColor}
            setLineColor={setLineColor}
            sensorValue={sensorValue}
            setSensorValue={setSensorValue}
            dataLength={dataLength}
            dateForm={dateForm}
            setStart={setStart}
            setEnd={setEnd}
            searchData={searchData}
            downloadData={downloadData}
        >
        </SensorDataPresenter>
    );


}
export default SensorDataContainer;