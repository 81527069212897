import React, { useState } from "react";
import * as s from "../AddProjectStyled";


import CodeMirror from "@uiw/react-codemirror";
import "codemirror/keymap/sublime";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/comment/comment";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/theme/neat.css";
import "codemirror/theme/dracula.css";
import "codemirror/theme/monokai.css";

const PythonEditor = ({ addPythonFile }) => {
  const [codeLine, setCodeLine] = useState('');

  const [fileTitle, setFileTitle] = useState("untitled");

  const Sk = window.Sk;

  const externalLibs = {
    "./numpy/__init__.js":
      "https://cdn.jsdelivr.net/gh/trinketapp/skulpt_numpy@master/numpy/__init__.js",
    "./matplotlib/__init__.js":
      "https://cdn.jsdelivr.net/gh/trinketapp/skulpt_matplotlib@master/matplotlib/__init__.js",
    "./turtle/__init__.js":
      "https://cdn.jsdelivr.net/gh/trinketapp/turtle@master/__init__.js",
    "./matplotlib/pyplot/__init__.js": {
      path:
        "https://cdn.jsdelivr.net/gh/trinketapp/skulpt_matplotlib@master/matplotlib/pyplot/__init__.js",
      dependencies: [
        "https://cdn.jsdelivr.net/gh/trinketapp/skulpt_matplotlib@master/deps/d3.min.js",
        "https://cdn.jsdelivr.net/gh/trinketapp/skulpt_matplotlib@master/deps/jquery.js",
      ],
    },
  };

  function outf(text) {
    var mypre = document.getElementById("output");
    mypre.innerHTML = mypre.innerHTML + text;
  }

  function builtinRead(file) {
    if (externalLibs[file] !== undefined) {
      return Sk.misceval.promiseToSuspension(
        fetch(externalLibs[file]).then(function (resp) {
          return resp.text();
        })
      );
    }

    if (
      Sk.builtinFiles === undefined ||
      Sk.builtinFiles.files[file] === undefined
    ) {
      throw "File not found: '" + file + "'";
    }

    return Sk.builtinFiles.files[file];
  }

  function runit() {
    var prog = codeLine;
    var mypre = document.getElementById("output");
    mypre.innerHTML = "";
    Sk.pre = "output";
    Sk.configure({
      output: outf,
      read: builtinRead,
      inputfunTakesPrompt: true,
      __future__: Sk.python3,
    });
    (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target =
      "mycanvas";

    Sk.TurtleGraphics.width = 1080;
    Sk.TurtleGraphics.height = 400;

    var myPromise = Sk.misceval.asyncToPromise(function () {
      return Sk.importMainWithBody(
        "<stdin>",
        false,
        prog,
        true
      );
    });
    myPromise.then(
      function (mod) {
        console.log("success");
      },
      function (err) {
        outf(err.toString());
      }
    );
  }

  const reset = () => {
    setCodeLine(
      `import turtle\nt = turtle.Turtle()\nt.forward(100)\nprint('Hello World')`
    );
    document.getElementById("output").innerHTML = "";
    if (
      document.getElementsByTagName("canvas").length > 0
    ) {
      Sk.TurtleGraphics.reset();
    }
  };

  function save(data, filename) {
    var file = new File([data], filename, {
      type: "text/x-python-script",
    });
    addPythonFile(file);
  }

  return (
    <s.PythonEditorContainer>
      <s.FlexRowWrapper>
        <s.PythonBlockWrapper
          style={{
            width: "70%",
          }}
        >
          <s.PythonBlockName
            style={{
              borderTopLeftRadius: 5,
              justifyContent: "space-between",
            }}
          >
            <input
              value={fileTitle}
              style={{
                fontSize: 20,
                background: "#f0f0f0",
                border: "none",
              }}
              onChange={(e) => {
                setFileTitle(e.target.value);
              }}
            />
            <div
              style={{
                display: "flex",
                gap: "1em",
              }}
            >
              <s.funcButton onClick={() => reset()}>
                초기화
              </s.funcButton>
              <s.funcButton
                onClick={() =>
                  save(codeLine, fileTitle + ".py")
                }
              >
                저장
              </s.funcButton>

              <s.funcButton onClick={() => runit()}>
                실행
              </s.funcButton>
            </div>
          </s.PythonBlockName>

          <div
            style={{
              height: 405,
            }}
          >
            <CodeMirror
              value={codeLine}
              options={{
                theme: "neat",
                tabSize: 2,
                lineNumbers: true,
                mode: "python",
              }}
              onChange={(v) => {
                setCodeLine(v.getValue());
              }}
            />
          </div>
        </s.PythonBlockWrapper>
        <s.PythonBlockWrapper
          style={{
            width: "30%",
          }}
        >
          <s.PythonBlockName
            style={{
              borderTopRightRadius: 5,
            }}
          >
            Output
          </s.PythonBlockName>
          <s.PythonOutPut id="output"></s.PythonOutPut>
        </s.PythonBlockWrapper>
      </s.FlexRowWrapper>
      <s.PythonBlockWrapper>
        <s.PythonBlockName>Canvas</s.PythonBlockName>
        <s.PythonCanvas id="mycanvas"></s.PythonCanvas>
      </s.PythonBlockWrapper>
    </s.PythonEditorContainer>
  );
};

export default PythonEditor;
