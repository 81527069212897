import React from "react";
import {
  Container,
  ImageIcon,
  Contents,
  TextBox,
  MainTitle,
  SubTitle,
  MainStrong,
  MainTitleBox,
  SubTitleBox,
} from "./HeaderStyled";

const Header = (props) => (
  <>
    <Container>
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <Contents>
          <TextBox>
            <MainTitleBox>
              <MainTitle>
                <MainStrong>수학</MainStrong>은<br />
                <MainStrong>인공지능</MainStrong> 시대
                <br />
                핵심능력
              </MainTitle>
            </MainTitleBox>

            <SubTitleBox>
              <SubTitle>
                <MainStrong>M</MainStrong>ATH <br />
                <MainStrong>AI</MainStrong> <br />
                <MainStrong>MAI</MainStrong>CLASS <br />
              </SubTitle>
            </SubTitleBox>
          </TextBox>

          <ImageIcon></ImageIcon>
        </Contents>
      </div>
    </Container>
  </>
);

export default Header;
