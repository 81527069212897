import styled from "styled-components";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const ContentContainer = styled.section`
  padding: 3% 0;
`;

export const TitleText = styled.h1`
  font-size: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 24px;
`;

export const PrevTitle = styled.h1`
  cursor: pointer;
`;

export const CurTitle = styled.h1`
  font-weight: 600;
`;

export const AddContainer = styled.div`
  margin-top: 5%;
`;

export const AddContentWrapper = styled.div`
  margin-bottom: 5%;
`;

export const TitleInputBox = styled.input`
  width: 100%;
  margin-top: 3%;
  padding: 2%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  font-size: 18px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
`;

export const ContentTextArea = styled.textarea`
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 400px;
  margin-top: 3%;
  padding: 2%;
  font-size: 18px;
  border: 1px solid #eeeeee;
  resize: none;
  border-radius: 5px;
`;

export const FlexRowBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowGapDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 85%;
`;

export const FileInputWrapper = styled.div`
  display: flex;
  gap: 1em;
  width: 16%;
`;

export const URLInputWrapper = styled.div`
  display: flex;
  gap: 1em;
  width: 16%;
`;

export const FileInputLabel = styled.label`
  display: flex;
  gap: 1em;
  padding: 0.5em 0.75em;
  align-items: center;
  cursor: pointer;
`;

export const URLInputLabel = styled.label`
  display: flex;
  gap: 1em;
  padding: 0.5em 0.75em;
  align-items: center;
  cursor: pointer;
`;

export const URLInputButton = styled.button`
width: 1px;
height: 1px;
padding: 0;
margin: -1px;
overflow: hidden;
clip: rect(0, 0, 0, 0);
border: 0;
  outline: none;
`;

export const FileInput = styled.input`
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const AddFileButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 600;
  cursor: pointer;
`;

export const UploadedFileBlock = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 1%;
`;

export const SubmitButton = styled.button`
  color: white;
  padding: 2% 3%;
  width: 15%;
  font-size: 18px;
  font-weight: 600;
  background-color: #f0b828;
`;

export const PythonToggleButton = styled.button`
  color: white;
  padding: 2% 3%;
  width: 18%;
  font-size: 18px;
  font-weight: 600;
  background-color: #f0b828;
`;

//Python

export const PythonEditorContainer = styled.div``;

export const FlexRowWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const PythonBlockWrapper = styled.div``;

export const PythonBlockName = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  padding: 8px;
  font-size: 20px;
  border: 1px solid white;
  background-color: #f0f0f0;
  color: black;
`;

export const PythonOutPut = styled.pre`
  border: 1px solid white;
  height: 380px;
  overflow-y: scroll;
  padding: 10px;
  border-left: 1px solid #f0f0f0;
`;

export const PythonCanvas = styled.div`
  border: 1px solid #f0f0f0;
  height: 400px;
`;

export const funcButton = styled.button`
  background-color: #45586c;
  color: white;
  font-size: 16px;
`;

// popup
export const UrlPopupBox = styled.div`
position: fixed;
width: 700px;
margin: 0 auto;
left: 0;
right: 0;
top: 40%;
background-color: #fff;
border: 2px solid black;
border-radius: 10px;
z-index: 30;
`;

export const UrlPopupWrapper = styled.div`
width: 90%;
margin: 0 auto;
line-height: 2;
`;

export const UrlTypeSection = styled.div`
width: 100%;
margin: 0 auto;
padding: 10px;
display: flex;
justify-content: left;
`;

export const UrlTypeText = styled.h2`
font-size: 20px;
margin-top: 5px;
margin-right: 20px;
`;

export const UrlTypeSelect = styled.div`
width: 50%;
text-align: left;
margin-right: 225px;
`;

export const UrlUrlText= styled.h2`
font-size: 20px;
margin-left: 10px;
`;

export const UrlInputBox = styled.input`
width: 100%;
padding: 2%;
margin-bottom: 10px;
-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
-moz-box-sizing: border-box; /* Firefox, other Gecko */
box-sizing: border-box;
font-size: 18px;
border: 1px solid #eeeeee;
border-radius: 5px;
`;

export const UrlSubmitRow = styled.div`
width: 100%;
margin: 0 auto;
padding: 10px;
display: flex;
justify-content: right;
`;

export const URLCloseButton = styled.button`
width: 5px;
height: 5px;
padding: 0;
margin: -1px;
clip: rect(0, 0, 0, 0);
border: 0;
  outline: none;
`;

export const UrlSubmitButton = styled.button`
  color: white;
  padding: 1% 2%;
  width: 20%;
  font-size: 18px;
  font-weight: 600;
  background-color: #f0b828;
`;