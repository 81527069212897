import moment from "moment";
import jwt_decode from "jwt-decode";

export const changeCurriculumName = (curriculumName) => {
  switch (curriculumName) {
    case "초등학교 3학년 1학기 ":
      return "덧셈뺄셈과 측정";
    case "초등학교 3학년 2학기 ":
      return "나눗셈과 분수";
    case "초등학교 4학년 1학기 ":
      return "큰수와 그래프";
    case "초등학교 5학년 1학기 ":
      return "수와연산";
    case "초등학교 6학년 2학기 ":
      return "도형";
    case "중학교 3학년 1학기 ":
      return "함수";  
    default:
      return "";
  }
}


export const trimText = (text = "", start, limit) =>
  text.length > limit
    ? `${text.slice(start, limit)}`
    : text;

/** 글자수 초과 ... */
export const textLengthOverCut = (txt, len, lastTxt) => {
  if(txt == null) {
    return null;
  }
  if (len == "" || len == null) { // 기본값
    len = 20;
  }
  if (lastTxt == "" || lastTxt == null) { // 기본값
      lastTxt = "...";
  }
  if (txt.length > len) {
      txt = txt.substr(0, len) + lastTxt;
  }
  return txt;
};

export const formatDate = (date) => {
  const theDate = new Date(date);
  return theDate.toLocaleDateString("ko", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

//data param example : 2020-07-17T13:24:52 -> YYYY.MM.DD
export const changeDateToFormat = (date) => {
  return moment(date).format("YYYY.MM.DD");
};

// hh로 할 경우 14시 -> 02시로 변경, HH로 할 경우 24시 다 표시
export const changeDateAllToFormat = (date) => {
  return moment(date).format("YYYY.MM.DD.HH:mm:ss");
};

// hh로 할 경우 14시 -> 02시로 변경, HH로 할 경우 24시 다 표시
export const changeDateForSearch = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

//HH:MM:SS -> HH:MM
export const changeTimeToFormat = (time) => {
  if (time) {
    return time?.substr(0, 5);
  } else {
    return "";
  }
};

//날짜 차이 계산
//param은 YYYY.MM.DD format
export const getDateDiff = (endDate, startDate) => {
  const endArray = endDate.split(".");
  const startArray = startDate.split(".");
  const endObj = new Date(
    endArray[0],
    Number(endArray[1]) - 1,
    endArray[2]
  );
  const startObj = new Date(
    startArray[0],
    Number(startArray[1]) - 1,
    startArray[2]
  );
  return (
    (endObj.getTime() - startObj.getTime()) /
    1000 /
    60 /
    60 /
    24 +
    1
  );
};

export const tokenCheck = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  if (token === "" || token === null) {
    return false;
  }
  let decode = jwt_decode(token);
  let exp = decode.exp;
  let now = Date.now() / 1000;
  if (exp < now) {
    localStorage.clear();
    return false;
  } else {
    return true;
  }
}


export const teacherCheck = () => {
  const res = JSON.parse(localStorage.getItem("userinfo"));
  const role = res.role;
  if (role === "TEACHER"){
    return true;
  }
  else{
    return false;
  }
}


export default changeDateAllToFormat;