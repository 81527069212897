/*********************************
//*** @Author : jeongin ***
//*** @Date : 2020.11.05 ***
//*** @Title : 내 프로젝트 페이지 presenter
*********************************/

import React from "react";
import LoginedSidebar from "../sections/LoginedSidebar";
import NotLoginedSidebar from "../sections/NotLoginedSidebar";
import Loader from "../Loader";
import { tokenCheck, textLengthOverCut } from "../../util/util";
import InfiniteScroll from './InfiniteScroll';

import * as s from "./ProjectPresenterStyled";

const ProjectPresenter = ({
  history,
  alldataList,
  jupyterHub,
  isLoading,
  fetchMoreData,
  hasMore,
}) => {
  const Elem = ({ i, alldata }) => {
    return (
      <s.AllBox
        key={i}
        onClick={() =>
          history.push(
            `/project/read/${alldata.workId}`
          )
        }
      >
        <s.AllItem>
          <s.Title>{alldata.workTitle}</s.Title>
          <s.SubTitle>{textLengthOverCut(alldata.workContent, 10, "...")}</s.SubTitle>
          <s.Date>
            {alldata.createdDate.slice(0, 10)}
          </s.Date>
        </s.AllItem>
      </s.AllBox>
    )
  }
  const renderer = ({ index }) => <Elem i={index} alldata={alldataList[index]} />

  return (
    <>
      { tokenCheck() ? (
        <LoginedSidebar />
      ) : (
          <NotLoginedSidebar />
        )}
      {
        isLoading ?
          <Loader />
          :
          <s.Container>
            <s.TitleWrapper>
              <s.FirstTitle>내 프로젝트</s.FirstTitle>
              <s.JupiterButton onClick={() => jupyterHub()}>
                내 주피터로 이동+
              </s.JupiterButton>
            </s.TitleWrapper>
            <s.ContentContainer>
              <InfiniteScroll
                dataLength={alldataList.length}
                hasMore={hasMore}
                next={fetchMoreData}
                loader={<Loader />}
                height={800}
                renderer={renderer}
                children={alldataList}

              />
            </s.ContentContainer>
            <s.ButtonPadding>
              <s.NewProject
                onClick={() => history.push("/project/add")}
              >
                프로젝트 추가+
              </s.NewProject>
            </s.ButtonPadding>
          </s.Container>
      }
    </>
  );
};

export default ProjectPresenter;
