import React from 'react';
import { Route, Redirect } from "react-router";

import {
    OuterContainer,
    Box,
    FirstText,
    SecondText,
    ErrorText,
    InputEmail,
    InputPassword,
    LoginButton,
    LastText,
    IconItem,
} from "./SignInPresenterStyled"

const SignInPresenter = ({ onChangeEmail, onChangePw, loginSuccess, handleKeypress, loginError }) => {
    return (
        <OuterContainer>
            <Box>
                <FirstText>이메일로 로그인</FirstText>
                <SecondText error={loginError} >* 발급된 이메일과 비밀번호로 로그인해주세요.</SecondText>
                <InputEmail type="text" placeholder="이메일을 입력해주세요." name="email" onChange={onChangeEmail}>
            
                </InputEmail>
                <InputPassword type="password" placeholder="비밀번호를 입력해주세요." name="pw" onChange={onChangePw} onKeyPress={handleKeypress}>

                </InputPassword>
                <LoginButton onClick={loginSuccess} >로그인</LoginButton>
                <ErrorText error={loginError} >* 등록된 유저가 아니네요!</ErrorText>

                <LastText>로그인 관련 문의하기</LastText>
            </Box>
            <IconItem
                bgUrl={
                    "https://cdn.maiclass.com/maiclass/images/intro/login/login-image.png"
                }
                style={{
                    left: "110%",
                    top: "20%",
                }}
            ></IconItem>
        </OuterContainer>
    )
};

export default SignInPresenter;