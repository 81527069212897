import styled from "styled-components";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const ContentContainer = styled.section`
  padding: 3% 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Message = styled.h2`
font-size: 20px;
font-weight: 600;
`;
