/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-05
 * @desc [description]
 */
import { loginedApi } from "./api-base";

export const roomApi = {
  getAllRooms: () =>
    loginedApi.get("/room/getall"),
  getRoomDetail: (roomId) =>
    loginedApi.get(`/room/read/${roomId}`),
  getLectures: (roomId) =>
    loginedApi.get(`/room/getLectures/${roomId}`),
  getMaterials: (roomId, lectureId) =>
    loginedApi.get(`/room/getMaterials/${roomId}/${lectureId}`),
  getCurriculumList: (roomId) =>
    loginedApi.get(`/room/${roomId}/curriculums`),
  getLectureList: (roomId, curriculumId) =>
    loginedApi.get(`/room/${roomId}/curriculum/${curriculumId}/lecture`),
  getMaterial: (roomId, materialId) =>
    loginedApi.get(`/room/${roomId}/material/${materialId}`),
  putMeetingRoom: (roomId, formData) =>
    loginedApi.put(`/room/room/${roomId}/meetingroom`, formData),
};
