/*********************************
//*** @Author : ydoni ***
//*** @Date : 2020.09.15 ***
//*** @Title : HowtoPresenter ***
*********************************/

import React from "react";
import {
    OuterContainer,
    //Head
    HeadVisualWrap,
    HeadTextWrap,
    MainTitle,
    SubTitle,
    MainStrong,
    //Content
    ContentWrap,
    ContentSection,
    InnerSection,
        //Info
    InfoSection,
    ListInfo,
    InfoBundle,
    InfoLogo,
    InfoTitle,
    InfoDescription,
        //Contact
    ContactSection,
    ContactBox,
    ContactTitleBox,
    InnerBox,
    ContactIcon,
    ContactDescription,
    InputFormWrapper,
    InputForm,
    InputBundle,
    InputLabel,
    InputText,
    InputFeedBack,
    SubmitBundle,
    InputDescription,
    InputSubmit
} from "./HowtoPresenterStyled"

const HowtoPresenter = ({onPostContact, onChangeForm, inputValidArr, schoolError, chargerError, phoneError, emailError}) => {
    return (
        <>
        <OuterContainer>
            <HeadVisualWrap>
                <div
                    style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                >
                <HeadTextWrap>
                    <SubTitle>마이클래스와 함께하는 방법</SubTitle>
                    <MainTitle>
                        HOW TO&nbsp;
                        <MainStrong>
                            START
                        </MainStrong>
                    </MainTitle>
                </HeadTextWrap>
                </div>
            </HeadVisualWrap>
        
            <ContentWrap>
                <ContentSection>
                    <InnerSection>
                        <InfoSection>
                            <ListInfo>
                                <InfoBundle>
                                    <InfoLogo
                                        bgUrl = {"https://cdn.maiclass.com/maiclass/images/intro/howtostart/start-icon-01.png"}>
                                    </InfoLogo>
                                    <InfoTitle>
                                        MathWare 전문가
                                    </InfoTitle>
                                    <InfoDescription>
                                        Mathware 전문가는 소프트웨어와 하드웨어 <br/>
                                        동작의 핵심 수학 원리를 이해하고 직접 <br/>
                                        디자인 할 수 있는 인공지능 시대 인재상 입니다. <br/>
                                        마이클래스의 연구진은 mathware 전문가로 <br/>
                                        구성되어 있으며, 직접 개발한 콘텐츠로 교육합니다. <br/>
                                    </InfoDescription>
                                </InfoBundle>
                                <InfoBundle>
                                    <InfoLogo
                                        bgUrl = {"https://cdn.maiclass.com/maiclass/images/intro/howtostart/start-icon-02.png"}>
                                    </InfoLogo>
                                    <InfoTitle>
                                        온라인 / 오프라인 수업 지원
                                    </InfoTitle>
                                    <InfoDescription>
                                        마이클래스의 모든 콘텐츠와 수업자료는 <br/>
                                        maiclass.com에서 진행되고 학생들의 수업 <br/>
                                        결과물은 각자의 계정에 저장되어 관리됩니다. <br/>
                                        별도의 설치 없이 프로그래밍이 가능하고, <br/>
                                        zoom을 사용해 실시간 원격수업이 가능하도록 지원합니다. <br/>
                                    </InfoDescription>
                                </InfoBundle>
                            </ListInfo>
                        </InfoSection>

                        <ContactSection>
                            <ContactBox>
                                <InnerBox>
                                    <ContactTitleBox>
                                        <ContactIcon
                                            bgUrl = {"https://cdn.maiclass.com/maiclass/images/intro/howtostart/start-icon-03.png"}
                                        ></ContactIcon>
                                        <ContactDescription>
                                            마이클래스의 시작을 위한 <br/>
                                            구체적인 로드맵을 제공해 드립니다.
                                        </ContactDescription>
                                    </ContactTitleBox>
                                    <InputFormWrapper>
                                        <InputForm>
                                            <InputBundle>
                                                <InputLabel>학교명</InputLabel>
                                                <InputText 
                                                    placeholder="학교명을 입력하세요." 
                                                    name="school"
                                                    onChange={onChangeForm}
                                                >
                                                </InputText>
                                                {!inputValidArr.schoolValid && (
                                                <InputFeedBack>
                                                    {schoolError}
                                                </InputFeedBack>
                                                )}
                                            </InputBundle>
                                            <InputBundle>
                                                <InputLabel>담당자명</InputLabel>
                                                <InputText 
                                                    placeholder="담당자 성함을 입력하세요." 
                                                    name="charger"
                                                    onChange={onChangeForm}
                                                >
                                                </InputText>
                                                {!inputValidArr.chargerValid && (
                                                <InputFeedBack>
                                                    {chargerError}
                                                </InputFeedBack>
                                                )}
                                            </InputBundle>
                                            <InputBundle>
                                                <InputLabel>전화번호</InputLabel>
                                                <InputText 
                                                    placeholder="전화번호를 입력하세요." 
                                                    name="phone"
                                                    onkeydown="return (event.keyCode!=86);"
                                                    onChange={onChangeForm}
                                                >
                                                </InputText>
                                                {!inputValidArr.phoneValid && (
                                                <InputFeedBack>
                                                    {phoneError}
                                                </InputFeedBack>
                                                )}
                                            </InputBundle>
                                            <InputBundle>
                                                <InputLabel>이메일</InputLabel>
                                                <InputText 
                                                    placeholder="이메일을 입력하세요." 
                                                    name="email"
                                                    onChange={onChangeForm}
                                                >
                                                </InputText>
                                                {!inputValidArr.emailValid && (
                                                <InputFeedBack>
                                                    {emailError}
                                                </InputFeedBack>
                                                )}
                                            </InputBundle>
                                            <SubmitBundle>
                                                <InputDescription>간단한 정보 기입 후 제출해 주세요.</InputDescription>
                                                <InputSubmit onClick={onPostContact}>문의하기</InputSubmit>
                                            </SubmitBundle>
                                        </InputForm>
                                    </InputFormWrapper>
                                </InnerBox>
                            </ContactBox>
                        </ContactSection>
                    
                    </InnerSection>
                </ContentSection>
            </ContentWrap>
        </OuterContainer>
        </>
    );
};

export default HowtoPresenter;