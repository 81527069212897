import Axios from "axios";
import { API_SERVER, API_LOCAL_SERVER } from "../config/config.js";
import { tokenCheck } from "../util/util";
import { Organization_Key } from "../config/config";
/*
 *
 * React Components에서 Backend 호출 시 아래 해당 하는 메서드를 호출한다.
 * Backend API 호출하므로 Java의 Controller URL과 매핑이 되야한다.
 *
 */

export const api = Axios.create({
  baseURL: `${API_SERVER}/maiclass/api`,
  // baseURL: `${API_LOCAL_SERVER}/maiclass/api`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Organization-Key": Organization_Key,
  },
});

export const loginedApi = Axios.create({
  baseURL: `${API_SERVER}/maiclass/api`,
  // baseURL: `${API_LOCAL_SERVER}/maiclass/api`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Organization-Key": Organization_Key,
  },
})

/*
    1. 요청 인터셉터
    2개의 콜백 함수를 받습니다.
*/
loginedApi.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem("token"));

    if (tokenCheck()) {
      config.headers.common["X-Auth-Token"] = token;
      return config;
    } else {
      window.location.href = "/login";
    }
  },
  function (error) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  }
);

/*
  2. 응답 인터셉터
  2개의 콜백 함수를 받습니다.
*/
loginedApi.interceptors.response.use(
  function (response) {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다. 
        .then() 으로 이어집니다.
    */
    if (response.headers["X-Auth-Token"] != undefined) {
      localStorage.setItem('token', JSON.stringify(response.headers.common["X-Auth-Token"]));
    }

    return response;
  },

  function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.    
    */
    return Promise.reject(error);
  }
);

export default api;
