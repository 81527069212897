/**
 * @author hanbin
 * @email
 * @create date 2020-11-06
 * @modify date 2020-11-17
 */
import { loginedApi } from "./api-base";

export const projectApi = {
  getListProjects: (page) =>
    loginedApi.get("/work/getlist",{
      params:{
        page: page,
      }
    }),
  getAllProjects: () =>
    loginedApi.get("/work/getall"),
  getProjectDetail: (projectId) =>
    loginedApi.get(`/work/read/${projectId}`),

  createProject: (formData) =>
    loginedApi.post(`/work/create/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  updateProject: (formData) =>
    loginedApi.put(`/work/update/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  deleteProject: (projectId) =>
    loginedApi.delete(`/work/delete/${projectId}`),
};
