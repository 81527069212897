/*********************************
//*** @Author : ydoni ***
//*** @Date : 2020.09.15 ***
//*** @Title : CurriculumPresenter ***
*********************************/

import React from "react";
import {
  OuterContainer,
  HeadVisualWrap,
  HeadTextWrap,
  SubTitle,
  MainStrong,
  MainTitle,
  ContentWrap,
  BigInnerContent,
  Elementary,
  IconBox,
  IconItem,
  SchoolTitle,
  TitleBox,
  SchoolIcon,
  CurriContents,
  CurriListBox,
  CurriList,
  CurriListItem,
  CurriTitle,
  Middle,
  Content,
  SLink,
  Smalltext,
  AIElementary,
} from "./CurriculumPresenterStyled";
import * as s from "./CurriculumPresenterStyled";

const CurriculumPresenter = () => {
  return (
    <>
      <OuterContainer>
        <HeadVisualWrap>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <HeadTextWrap>
              <SubTitle>
                수학과 인공지능을 접목한 마이클래스의 수학
                교실
              </SubTitle>
              <MainTitle>
                MAICLASS&nbsp;
                <MainStrong>스쿨</MainStrong>
              </MainTitle>
            </HeadTextWrap>
          </div>
        </HeadVisualWrap>

        {/* 커리큘럼 내용 */}
        <Content>
          <ContentWrap>
            {/* 초등학교 */}
            <BigInnerContent>
              <Elementary>
                <IconBox>
                  <IconItem
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-ele-icon-01.png"
                    }
                    style={{
                      top: "75px",
                      left: "45px",
                    }}
                  ></IconItem>
                  <IconItem
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-ele-icon-02.png"
                    }
                    style={{
                      top: "190px",
                      left: "150px",
                    }}
                  ></IconItem>
                  <IconItem
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-ele-icon-03.png"
                    }
                    style={{
                      top: "45px",
                      left: "135px",
                    }}
                  ></IconItem>
                  <IconItem
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-ele-icon-04.png"
                    }
                    style={{
                      top: "165px",
                      left: "55px",
                    }}
                  ></IconItem>
                  <IconItem
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-ele-icon-05.png"
                    }
                    style={{
                      top: "115px",
                      left: "115px",
                    }}
                  ></IconItem>
                </IconBox>

                <TitleBox>
                  <SchoolIcon
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-icon-01.png"
                    }
                  ></SchoolIcon>
                  <SchoolTitle>초등학교</SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriTitle>저학년 1학기</CurriTitle>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"1"}> */}
                        1학년 1학기
                        {/* </CLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor={false}>
                        {/* <SLink to={`/curriculum/${3}`}> */}
                          2학년 1학기
                        {/* </SLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${5}`}>
                          3학년 1학기
                        </SLink>
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriTitle>저학년 2학기</CurriTitle>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"2"}> */}
                        1학년 2학기
                        {/* </CLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor={false}>
                        {/* <SLink to={`/curriculum/${4}`}> */}
                          2학년 2학기
                        {/* </SLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${6}`}>
                          3학년 2학기
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>

                  <CurriListBox>
                    <CurriList>
                      <CurriTitle>고학년 1학기</CurriTitle>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${7}`}>
                          4학년 1학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${9}`}>
                          5학년 1학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${11}`}>
                          6학년 1학기
                        </SLink>
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriTitle>고학년 2학기</CurriTitle>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${8}`}>
                          4학년 2학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${10}`}>
                          5학년 2학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${12}`}>
                          6학년 2학기
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </Elementary>
            </BigInnerContent>

            <BigInnerContent>
              {/* 중학교 */}
              <Middle>
                <TitleBox>
                  <SchoolIcon
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-icon-02.png"
                    }
                  ></SchoolIcon>
                  <SchoolTitle>중학교</SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriTitle>1학기</CurriTitle>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${13}`}>
                          1학년 1학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${15}`}>
                          2학년 1학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${17}`}>
                          3학년 1학기
                        </SLink>
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriTitle>2학기</CurriTitle>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${14}`}>
                          1학년 2학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${16}`}>
                          2학년 2학기
                        </SLink>
                      </CurriListItem>
                      <CurriListItem bgColor>
                        <SLink to={`/curriculum/${18}`}>
                          3학년 2학기
                        </SLink>
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </Middle>

              {/* 고등학교 */}
              <Middle>
                <TitleBox>
                  <SchoolIcon
                    bgUrl={
                      "https://cdn.maiclass.com/maiclass/images/intro/classes/curri-icon-03.png"
                    }
                  ></SchoolIcon>
                  <SchoolTitle>고등학교</SchoolTitle>
                </TitleBox>

                {/* 커리큘럼 리스트 */}
                <CurriContents>
                  <CurriListBox>
                    <CurriList>
                      <CurriTitle>1학기</CurriTitle>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"19"}> */}
                        1학년 1학기
                        {/* </CLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"21"}> */}
                        2학년 1학기
                        {/* </CLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"23"}> */}
                        3학년 1학기
                        {/* </CLink> */}
                      </CurriListItem>
                    </CurriList>

                    <CurriList>
                      <CurriTitle>2학기</CurriTitle>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"20"}> */}
                        1학년 2학기
                        {/* </CLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"22"}> */}
                        2학년 2학기
                        {/* </CLink> */}
                      </CurriListItem>
                      <CurriListItem bgColor={false}>
                        {/* <CLink href = {url+"24"}> */}
                        3학년 2학기
                        {/* </CLink> */}
                      </CurriListItem>
                    </CurriList>
                  </CurriListBox>
                </CurriContents>
              </Middle>
            </BigInnerContent>
          </ContentWrap>
        </Content>
      </OuterContainer>
    </>
  );
};

export default CurriculumPresenter;
