import styled from "styled-components";

export const HeaderSection = styled.section`
  padding-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonTitleWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;
  margin-left: 3%;
`;

export const Description = styled.p`
  color: gray;
`;
