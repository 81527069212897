export const ThemeColor = "#f0b828";

export const DeepOrange = "#EB9D28";

export const LightTheme = "rgba(240, 184, 40, 0.05)";

export const LightGray = "#cccccc";

export const Gray = "#5e5e5e";

export const GrayBg = "#f8f8f8"

export const Black = "#221e1f";



