/**
 * @author hanbin
 * @email
 * @create date 2020-10-26
 * @modify date 2020-11-18
 * @desc MaiClass LoginedSideBar
 */

import React from "react";
import { withRouter } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { teacherCheck } from "../../util/util";


import {
  Container,
  LoginedSidebar,
  AdminInfo,
  ProfileText,
  SubText,
  RoomSubList,
  RoomSubItem,
  SubSLink,
  List,
  Item,
  SLink,
  Logout,
} from "./LoginedSidebarStyled";


export default withRouter(({ location: { pathname }, history }) => {

  const LogoutOnClick = () => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <>
    {teacherCheck() ? (
    <Container>
      <LoginedSidebar>
        <List>
          <AdminInfo>
          <FaUserCircle size={48} color={"#F0B828"} opacity={0.3}/>
            <ProfileText>
              {
                JSON.parse(localStorage.getItem("userinfo"))
                  .username
              }
            </ProfileText>
            <SubText>더보기</SubText>
          </AdminInfo>
          <SLink
            to="/mainpage"
            current={pathname.includes("mainpage")}
          >
            <Item current={pathname.includes("mainpage")}>
              홈
          </Item>
          </SLink>
          <SLink
            to="/classroom"
            current={
              pathname.includes("classroom") ||
              pathname.includes("classin")
            }
          >
            <Item
              current={
                pathname.includes("classroom") ||
                pathname.includes("classin")
              }
            >
              내 학급
          </Item>
          </SLink>
          <RoomSubList current={pathname.includes("classin")}>
            {/* <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>알림</RoomSubItem>
            </SubSLink> */}
            <SubSLink
              to="/classin/curriculum"
              current={
                pathname.includes("classin") &&
                pathname.includes("curriculum")
              }
            >
              <RoomSubItem
                current={
                  pathname.includes("classin") &&
                  pathname.includes("curriculum")
                }
              >
                교육과정
            </RoomSubItem>
            </SubSLink>
            {/* <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>질문</RoomSubItem>
            </SubSLink>
            <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>
                과제함
            </RoomSubItem>
            </SubSLink>
            <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>
                학생목록
            </RoomSubItem>
            </SubSLink> }
            { <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>
                출결일정
            </RoomSubItem>
            </SubSLink> */}
            <SubSLink
              to="/classin/meetingRoom"
              current={
                pathname.includes("classin") &&
                pathname.includes("meetingRoom")
              }
            >
              <RoomSubItem
                current={
                  pathname.includes("classin") &&
                  pathname.includes("meetingRoom")
                }
              >
                실시간 강의실
            </RoomSubItem>
            </SubSLink>
          </RoomSubList>
          <SLink
            to="/project"
            current={pathname.includes("project")}
          >
            <Item current={pathname.includes("project")}>
              내 프로젝트
          </Item>
          </SLink>
          <SLink
            to="/datahub"
            current={pathname.includes("datahub")}
          >
            <Item current={pathname.includes("datahub")}>
              내 데이터
          </Item>
          </SLink>
        </List>
        <Logout onClick={LogoutOnClick}>로그아웃</Logout>
      </LoginedSidebar>
    </Container>) : (<Container>
      <LoginedSidebar>
        <List>
          <AdminInfo>
          <FaUserCircle size={48} color={"#F0B828"} opacity={0.3}/>
            <ProfileText>
              {
                JSON.parse(localStorage.getItem("userinfo"))
                  .username
              }
            </ProfileText>
            <SubText>더보기</SubText>
          </AdminInfo>
          <SLink
            to="/mainpage"
            current={pathname.includes("mainpage")}
          >
            <Item current={pathname.includes("mainpage")}>
              홈
          </Item>
          </SLink>
          <SLink
            to="/classroom"
            current={
              pathname.includes("classroom") ||
              pathname.includes("classin")
            }
          >
            <Item
              current={
                pathname.includes("classroom") ||
                pathname.includes("classin")
              }
            >
              내 학급
          </Item>
          </SLink>
          <RoomSubList current={pathname.includes("classin")}>
            {/* <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>알림</RoomSubItem>
            </SubSLink> */}
            <SubSLink
              to="/classin/curriculum"
              current={
                pathname.includes("classin") &&
                pathname.includes("curriculum")
              }
            >
              <RoomSubItem
                current={
                  pathname.includes("classin") &&
                  pathname.includes("curriculum")
                }
              >
                교육과정
            </RoomSubItem>
            </SubSLink>
            {/* <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>질문</RoomSubItem>
            </SubSLink>
            <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>
                과제함
            </RoomSubItem>
            </SubSLink>
            <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>
                학생목록
            </RoomSubItem>
            </SubSLink>
            <SubSLink
              to=""
              current={false}>
              <RoomSubItem current={false}>
                출결일정
            </RoomSubItem>
            </SubSLink> */}
            <SubSLink
              to="/classin/meetingRoom"
              current={
                pathname.includes("classin") &&
                pathname.includes("meetingRoom")
              }
            >
              <RoomSubItem
                current={
                  pathname.includes("classin") &&
                  pathname.includes("meetingRoom")
                }
              >
                실시간 강의실
            </RoomSubItem>
            </SubSLink>
          </RoomSubList>
          <SLink
            to="/project"
            current={pathname.includes("project")}
          >
            <Item current={pathname.includes("project")}>
              내 프로젝트
          </Item>
          </SLink>
          <SLink
            to="/datahub"
            current={pathname.includes("datahub")}
          >
            <Item current={pathname.includes("datahub")}>
              내 데이터
          </Item>
          </SLink>
        </List>
        <Logout onClick={LogoutOnClick}>로그아웃</Logout>
      </LoginedSidebar>
    </Container>) }
    </>
  )
});
