/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-11
 */

import React from "react";
import RoomBox from "./RoomBox";
import * as s from "./ClassroomStyled";
import NotLoginedSidebar from "../sections/NotLoginedSidebar";
import LoginedSidebar from "../sections/LoginedSidebar";
import Loader from "../Loader";
import {tokenCheck} from "../../util/util";

const ClassroomPresenter = ({
  classroomList,
  isLoading,
  clickRoom,
}) => {
  return (
    <div>
      {tokenCheck() ? (
          <LoginedSidebar />
        ) : (
          <NotLoginedSidebar />
        )}
      {
        isLoading ?
          <Loader />
          :
          <s.Container>
            <s.ContentsContainer>
              <s.ContentWrapper>
                <s.ContentTitle>현재 내 학급</s.ContentTitle>
                <s.ContentFlexRowDiv>
                  {classroomList &&
                    classroomList.map((classroom, index) =>
                      classroom.state === "join" ? (
                        <RoomBox
                          key={index}
                          classroom={classroom}
                          clickRoom={clickRoom}
                        ></RoomBox>
                      ) : null
                    )}
                </s.ContentFlexRowDiv>
              </s.ContentWrapper>
            </s.ContentsContainer>
            <s.ContentsContainer>
              <s.ContentWrapper>
                <s.ContentTitle>지난 내 학급</s.ContentTitle>
                <s.ContentFlexRowDiv>
                  {classroomList &&
                    classroomList.map((classroom, index) =>
                      classroom.state !== "join" ? (
                        <RoomBox
                          key={index}
                          classroom={classroom}
                          clickRoom={clickRoom}
                        ></RoomBox>
                      ) : null
                    )}
                </s.ContentFlexRowDiv>
              </s.ContentWrapper>
            </s.ContentsContainer>
          </s.Container>
      }
    </div>
  );
};

export default ClassroomPresenter;
