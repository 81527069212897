import styled from "styled-components";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const ContentContainer = styled.section`
  padding: 3% 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Message = styled.h2`
font-size: 20px;
font-weight: 600;
`;


export const HeaderSection = styled.section`
  padding-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonTitleWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const PrevTitle = styled.p`
  font-size: 25px;
  font-weight: 400;
  margin-left: 3%;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;
  margin-left: 3%;
`;

export const Description = styled.p`
  color: gray;
`;

export const Button = styled.button`
  width: auto;
  background-color: #EFEFEF;
  align-items: center;
  color: black;
`;

export const BoxContainer = styled.div`
    width: 100%;
    text-align: left;
    margin: 0 auto;
`;

export const BoxTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
`;

export const ContentText = styled.p`
    margin-top: 1%;
    margin-left: 3%;
    margin-bottom: 1%;
    font-size: 20px;
    font-weight: 300;
`;

export const PythonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PyMoveButton = styled.button`
  width: 5%;
  outline: none;
`;