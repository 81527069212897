import styled from "styled-components";
import * as color from "../../util/Style";

export const OuterContainer = styled.div`
    width: 100%;
`;

// 1080px 기준은 Container로
export const Container = styled.div`
    width: 1080px;
    margin: 0 auto;
`;

export const HeadVisualWrap = styled.div`
    background: url("https://cdn.maiclass.com/maiclass/images/intro/zero/zero-main.jpg") no-repeat center;
    height: 300px;
    /* overflow: hidden; */
    position: relative;
    background-size: cover;
`
export const HeadTextWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 1080px;
    transform: translateX(-50%);
    box-sizing: border-box;
    z-index: 30;
    text-align: right;
`

export const HeadTextContent = styled.p`
    font-size: 30px;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    line-height: 1.6;
    letter-spacing: -0.25px;
`

export const MainTitle = styled.h1`
  font-weight: bold;
  font-size: 46px;
  color: #fff;
  line-height: 1.2em;
`;

export const SubTitle = styled(MainTitle)`
  font-weight: 400;
  font-size: 24px;
  text-align: right;
  margin-bottom: 10px;
`;

export const MainStrong = styled.strong`
  font-weight: bold;
  color: #F0B828;
`;

//content

export const ContentWrap = styled.div`
   
    padding: 60px 0;
    background-color: ${(props) => props.color};
`;

export const Inner = styled.div`
 width: 1080px;
    margin: 0 auto;
`;

export const Title = styled.h3`
  font-size: 28px;
  font-weight: 500;
  margin-top: 40px;
  color: ${(props) => props.orange && color.ThemeColor};
`;

export const InfoDescription = styled.p`
    display: block;
    margin-top: 30px;
    font-size: 18px;
    line-height: 1.3;
    color: #666;
`;

export const ItemContainer = styled.ul`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
`;

export const Item = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 265px;
    background-color: ${(props) => props.index%2 === 0 ? color.ThemeColor : color.DeepOrange};
    border-radius: 90%;
    color: #fff;
    font-size: 1.125rem;
    text-align: center;
    margin-right: ${(props) => props.index === 3 ? 0 : "15px"};
`;

export const ItemImage = styled.div`
    padding-bottom: 20px;
`;


//진행주체

export const ProjectWrap = styled(Inner)`
    display: flex;
`;
export const LeftContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;