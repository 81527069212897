import React, { useState } from "react";
import ZeroPresenter from "./ZeroPresenter";

const ZeroContainer = (props) => {

  const projectItem = [
    {
      title: "제로 모델 데이터 학습",
      src: "https://cdn.maiclass.com/maiclass/images/intro/zero/zero-icon-01.png"
    },
    {
      title: "제로 앱 (App)",
      src: "https://cdn.maiclass.com/maiclass/images/intro/zero/zero-icon-02.png"
    },
    {
      title: "제로 박스 (최적경로)",
      src: "https://cdn.maiclass.com/maiclass/images/intro/zero/zero-icon-03.png"
    },
    {
      title: "제로 로봇",
      src: "https://cdn.maiclass.com/maiclass/images/intro/zero/zero-icon-04.png"
    },

  ];

  return (
    <ZeroPresenter
    projectItem={projectItem}
    ></ZeroPresenter>
  );
};

export default ZeroContainer;
