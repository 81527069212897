import styled from "styled-components";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const ContentContainer = styled.section`
  padding: 0 1% 3% 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  height: 50px;
  margin-bottom: 20px;
`;

export const FirstTitle = styled.h3`
  width: 30%;
  font-size: 30px;
  padding: 40px;
`;

export const JupiterButton = styled.button`
  background-color: #F0B828;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 45%;
  margin-right: 1%;
  outline: none;
  padding: 10px 15px;
`;

export const LeftAllBox = styled.button`
  width: 40%;
  margin-top: -11px;
`;

export const AllBox = styled.button`
  margin-top: 20px;
  margin-left: 30px;
  border: 1px solid black;
  width: 95%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  outline: none;
  cursor: pointer;
  &:hover{
    background-color: rgba(240, 184, 40, 1);
  }
`;

export const AllItem = styled.div` 
  width: 100%;
  padding: 9px;
  font-size: 1.125rem;

  &:after {
    clear: both;
    content: "";
    display: block;
  }
`;

export const Title = styled.h2`
  width: 43%;
  font-weight: bold;
  display: inline-block;
  float: left;
  text-align: left;
`;

export const SubTitle = styled.h3`
  
  display: inline;
`;

export const Date = styled.h4`
  display: inline;
  float: right;
`;

export const NewProject = styled.button`
  border: 1px solid gray;
  border-style: dashed;
  width: 92%;
  height: 60px;
  border-radius: 10px;
  margin-left: 40px;
  text-align: center;
  color: gray;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
`;

export const ButtonPadding = styled.div`
  padding-bottom: 50px;
`;