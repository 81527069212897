/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-12-14
 * 
 * @author jeongin
 * @email
 * @modify date 2020-12-22
 */

import React from "react";
import * as s from "./ClassroomMeetingRoomStyied";
import NotLoginedSidebar from "../../sections/NotLoginedSidebar";
import LoginedSidebar from "../../sections/LoginedSidebar";
import { tokenCheck, teacherCheck } from "../../../util/util";
import MakeRoomModal from "./MakeRoomModal";


const ClassroomMeetingRoomPresenter = ({GotoRoom, isModalOn, onClickModal, addUrl}) => {
  return (
    <>
      {tokenCheck() ? (
          <LoginedSidebar />
        ) : (
          <NotLoginedSidebar />
        )}
        
      {teacherCheck() ? (
        <>
          <s.Container>
            {
              isModalOn?
                <MakeRoomModal 
                onClickModal={onClickModal}
                addUrl={addUrl}
                />
                :null
            }
            <s.ClassRoomContainer>
               <s.MakeRoom onClick={onClickModal}>
                 <s.Image bgUrl={"https://cdn.maiclass.com/maiclass/images/intro/classes/meeting-add-icon.png"}></s.Image>
                  <s.ButtonText>
                    강의실 등록 / 재등록 +
                  </s.ButtonText>
               </s.MakeRoom>
                <s.MakeRoom onClick={GotoRoom}>
                <s.Image bgUrl={"https://.maiclass.com/maiclass/images/intro/classes/meeting-zoom-icon.png"}></s.Image>
                  <s.ButtonText>
                    강의실 접속 →
                  </s.ButtonText>
                </s.MakeRoom>
            </s.ClassRoomContainer>
          </s.Container></>
      ) : (
          <s.Container>
            <s.Message>실시간 강의실 접속 중입니다.</s.Message>
          </s.Container>
      )} 
    </>
  );
};

export default ClassroomMeetingRoomPresenter;
