import styled from "styled-components";

export const ContentBoxContainer = styled.div`
  width: 43%;
  margin-bottom: 2%;
  height: 150px;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #221e1f;
  border-radius: 8px;
  &:hover {
    background-color: rgba(240, 184, 40, 0.1);
  }
`;

export const IconTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconImage = styled.img`
  width: 75px;
  height: 75px;
`;

export const BoxTitle = styled.h1`
  margin-left: 5%;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
`;

export const DescriptionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  box-sizing: border-box;
`;

export const Description = styled.p`
  color: gray;
  padding-right: 20px;
  box-sizing: border-box;
  width: 80%;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #f0b828;
  align-items: center;
  color: white;
  cursor: pointer;
`;
