/**
 * @author hanbin
 * @email
 * @create date 2020-10-26
 * @modify date 2020-10-27
 * @desc Curriculum 리스트 페이지
 */

import React, { useEffect, useState } from "react";
import { roomApi } from "../../../api/api-room";
import ClassroomCurriculumPresenter from "./ClassroomCurriculumPresenter";
import { useLocalStorage } from "../../../util/useLocalStorage";

const ClassroomCurriculumContainer = ({ history }) => {
    const [curriculumList, setCurriculumList] = useState([]);
    const [lectureList, setLectureList] = useState([]);
    const [materialList, setMaterialList] = useState([]);
    const [stageText, setStageText] = useState("");
    const [step, setStep] = useState(0);
    const [curriculumId, setCurriculumId] = useState(0);
    const [lectureId, setLectureId] = useState(0);
    const [materialId, setMaterialId] = useState(0);
    const [material, setMaterial] = useState({});
    const [lectureTitle, setLectureTitle] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [attachmentList, setAttachmentList] = useState({
        pdfList: [],
        htmlList: [],
        pyList: [],
        entryList: [],
        scratchList: [],
        imgList: [],
    });

    const goToStep0 = () => {
        setStep(0);
    };

    const goToStep2 = () => {
        setStep(2);
    };

    const goToStep1 = () => {
        setStep(1);
    };

    const goToStep3 = () => {
        setStep(3);
    };

    const [room, setRoom] = useLocalStorage('room', {});

    const fetchData = async () => {
        let res = null;
        let resultData = null;

        // step0
        if (step === 0) {
            setStageText("");
            try {
                res = await roomApi.getCurriculumList(room.roomId);
            } catch(e) {
                console.log(e);
            } finally {
                if(res !== undefined) {
                    let curriculums = res.data.list;
                    if (curriculums.length === 0){
                        setStep(4);
                    } else {
                        setCurriculumList(curriculums);
                    }
                } else {
                    setStep(4);
                }
                setIsLoading(false);
            }
        }

        //step 1
        if (step === 1) {
            try {
                res = await roomApi.getLectureList(room.roomId,curriculumId);
            } catch (e) {
                console.error(e);
            } finally {
                if (res === undefined) {
                    setStep(4);
                } else {
                    let lectures = res.data.list;
                    if (lectures.length === 0) {
                        setStep(4);
                    } else {
                        setLectureList(
                            //lectureNo 기준으로 정렬
                            lectures.sort((a, b) => {
                                return a.lectureNo - b.lectureNo;
                            })
                        );
                        setStageText(
                            lectures[0].curriculum
                        );
                    }
                }
                setIsLoading(false);
            }
        } else if (step === 2) {
            // step 2
            try {
                res = await roomApi.getMaterials(room.roomId, lectureId)
            } catch (e) {
                console.error(e);
            } finally {
                if (res === undefined) {
                    setStep(4);
                } else {
                    resultData = res.data.data;
                    setLectureTitle(resultData.lectureNo + ". " + resultData.lectureTitle);
                    setMaterialList(
                        //lectureNo 기준으로 정렬
                        resultData.materialList.sort((a, b) => {
                            return a.materialNo - b.materialNo;
                        })
                    );
                }
                setIsLoading(false);
            }
        } else if (step === 3) {
            // step 3
            try {
                res = await roomApi.getMaterial(room.roomId, materialId);
            } catch (e) {
                // console.error(e);
            } finally {
                if (res === undefined) {
                    setStep(4);
                } else {
                    resultData = res.data.data;
                    let pdfs = [];
                    let pys = [];
                    let htmls = [];
                    let imgs = [];
                    setMaterial(resultData);
                    for (let attachment of resultData.attachments) {
                        if (attachment.contentType === "application/pdf") {
                            pdfs.push(attachment);
                        } else if (attachment.contentType === "text/x-python-script" || attachment.contentType === "text/plain") {
                            pys.push(attachment);
                        } else if (attachment.contentType === "text/html") {
                            htmls.push(attachment);
                        } else if (attachment.contentType === "image/png" || attachment.contentType === "image/gif" || attachment.contentType === "image/jpeg") {
                            imgs.push(attachment);
                        }
                    }
                    let scratchs = [];
                    let entrys = [];
                    for (let url of resultData.materialUrls) {
                        if (url.type === "scratch") {
                            scratchs.push(url);
                        } else if (url.type === "entry") {
                            entrys.push(url);
                        }
                    }
                    setAttachmentList({
                        pdfList: pdfs.sort((a,b) => a.id - b.id),
                        htmlList: htmls.sort((a,b) => a.id - b.id),
                        pyList: pys.sort((a,b) => a.id - b.id),
                        entryList: entrys.sort((a,b) => a.id - b.id),
                        scratchList: scratchs.sort((a,b) => a.id - b.id),
                        imgList: imgs.sort((a,b) => a.id - b.id),
                    });
                }
                setIsLoading(false);
            }
        } else {

        }
    };

    useEffect(() => {
        fetchData();
        window.scrollTo(0,0);
    }, [step]);

    return (
        <ClassroomCurriculumPresenter
            history={history}
            curriculumList={curriculumList}
            lectureList={lectureList}
            materialList={materialList}
            material={material}
            stageText={stageText}
            step={step}
            lectureTitle={lectureTitle}
            setLectureId={setLectureId}
            setMaterialId={setMaterialId}
            setCurriculumId={setCurriculumId}
            goToStep0={goToStep0}
            goToStep1={goToStep1}
            goToStep2={goToStep2}
            goToStep3={goToStep3}
            pdfList={attachmentList.pdfList}
            scratchList={attachmentList.scratchList}
            entryList={attachmentList.entryList}
            pyList={attachmentList.pyList}
            imgList={attachmentList.imgList}
            isLoading={isLoading}
        ></ClassroomCurriculumPresenter>
    );
};

export default ClassroomCurriculumContainer;
