/**
 * @author jeongin
 * @email
 * @create date 2020-11-10
 * @modify date 2020-11-10
 * @desc 마이클래스 NotLoginedSideBar
 */

import React from "react";
import { withRouter } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

import {
  Container,
  LoginedSidebar,
  AdminInfo,
  ProfileText,
  List,
  SLink,
  SLinkText,
} from "./NotLoginedSidebarStyled";

export default withRouter(({ location: { pathname } }) => (
  <Container>
    <LoginedSidebar>
      <List>
        <AdminInfo>
        <FaUserCircle size={48} color={"#F0B828"} opacity={0.3}/>
          <ProfileText>로그인 하세요</ProfileText>
        </AdminInfo>
        <SLink
          to="/login"
          current={pathname.includes("login")
          }
        ><SLinkText>로그인</SLinkText>
        </SLink>
        <SLink
          to="/signup"
          current={pathname.includes("signup")}
        >
        <SLinkText>회원가입</SLinkText>
        </SLink>
      </List>
    </LoginedSidebar>
  </Container>
));
