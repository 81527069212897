import {api} from "./api-base"

/*********************************
//*** @Author : hanbin ***
//*** @Date : 2020.09.18 ***
//*** @Title : api contact ***
*********************************/

export const contactApi = {
    registerContact: (formData) => 
        api.post("/update/admin/contact",formData)
    
};