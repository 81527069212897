import React, { useEffect, useState } from "react";
import ProjectPresenter from "./ProjectPresenter";
import { projectApi } from "../../api/api-project";
import { userApi } from "../../api/api-user";

const ProjectContainer = ({ history }) => {
  const [alldataList, setAlldataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);


  const fetchData = async () => {
    let res = null;
    try {
      res = await projectApi.getListProjects(nextPage);
    } catch (e) {
      console.error(e);
    } finally {
      if (res != null) {
        const nextItems = alldataList.concat(res.data.list.content);
        setNextPage(nextPage+1);
        if(res.data.list.last){
          setHasMore(false);
        }
        setAlldataList(nextItems);
      }
      setIsLoading(false);
    }
  }

  const jupyterHub = async () => {
    let res = null;
    try {
      res = await userApi.getUserDetail();
    } catch (e) {
      console.error(e);
    } finally {
      window.open(res.data.data.fullJupyterUrl, "JupyterHub", "width=1500, height=1000, left="+(window.screenLeft+(document.body.clientWidth/2)-(2000/2))+',top='+(window.screenTop+(document.body.clientHeight/2)-(1000/2))+',location=no,status=yes,scrollbars=yes');
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchMoreData = async() => {
    let res = null;
    try {
      res = await projectApi.getListProjects(nextPage);
    } catch (e) {
      console.error(e);
    } finally {
      if (res != null) {
        const nextItems = alldataList.concat(res.data.list.content);
        setNextPage(nextPage+1);
        if(res.data.list.last){
          setHasMore(false);
        }
        setAlldataList(nextItems);
      }
    }
  }

  return (
    <ProjectPresenter
      alldataList={alldataList}
      history={history}
      fetchMoreData={fetchMoreData}
      jupyterHub={jupyterHub}
      isLoading={isLoading}
      hasMore={hasMore}
    >
    </ProjectPresenter>
  );
};

export default ProjectContainer;
