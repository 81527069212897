import styled from "styled-components";

export const Container = styled.div`
  width: 1080px;
  min-height: calc(100vh - 330px);
  position: relative;
  margin: 0 auto;
`;

export const ContentContainer = styled.section`
  padding: 3% 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AlarmHeader = styled.div`
  padding: 2%;
  width: 100%;
  border: 1px solid #f0b828;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AlarmText = styled.div`
  font-size: 20px;
  margin-left: ${(props) =>
    props.marginLeft ? `${props.marginLeft}%` : 0};
`;

export const ContentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 5%;
`;

//ContentBox

export const ContentBox = styled.div`
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 42%;
  height: 26vh;
  margin-bottom: 3%;
  padding: 3%;
`;

export const ContentTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5%;
`;

export const FlexRowDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
`;

export const FlexRowHalfText = styled.div`
  width: 50%;
  font-size: 20px;
  font-weight: 600;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5%;
`;

export const ListItem = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin-right: 5%;
  color: #5e5e5e;
  padding: 2% 0;
  /* border-top: 1px solid #cccccc; */
  border-bottom: 1px solid #cccccc;
`;

export const ListText = styled.div`
  color: #5e5e5e;
  font-size: 16px;
`;

//ProjectBox
export const ListContainer = styled.div``;

export const ProjectListItem = styled.div`
  color: #5e5e5e;
  font-size: 16px;
  border-bottom: 1px solid #cccccc;
  padding: 2% 0;
  display: flex;
  justify-content: space-between;
`;

export const ProjectTitle = styled.div`
  font-size: 20px;
  width: 25%;
`;

export const ProjectContent = styled.div`
  color: #5e5e5e;
  font-size: 14px;
  width: 50%;
`;

export const ProjectDate = styled.div`
  color: #5e5e5e;
  font-size: 14px;
`;
