import styled from "styled-components";

export const BoxContainer = styled.div`
  width: 100%;
  text-align: left;
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleBtnContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const TitleButton = styled.button`
  background-color: #f0b828;
  align-items: center;
  height: auto;
  width: auto;
  color: white;
  outline: none;
  margin-right: 10px;
  border-radius: 50%;
`;

export const BtnLabel = styled.label`
  font-size: 18px;
  margin-right: 10px;
`;

export const BoxTitle = styled.h1`
  width: 60%;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-left: 3%;
  margin-bottom: 1%;
  text-align: left;
`;

export const ImgSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  overflow: hidden;
`;

export const Img = styled.img`
  width: 80% !important;
  height: auto !important;
`;

export const PDFDocumentWrapper = styled.div`
  canvas {
    border: 1px solid #221e1f;
    border-radius: 8px;
    width: 94% !important;
    height: auto !important;
    margin-left: 3%;
  }
`;

export const PageSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  margin: 0 auto;
`;

export const Button = styled.button`
  width: 5%;
  align-items: center;
  outline: none;
`;

export const MoreButton = styled.button`
  height: 35px;
  width: 9%;
  align-items: center;
  outline: none;
  background-color: #f0b828;
  color: white;
  margin-right: 1%;
`;

export const PageText = styled.p`
  margin-top: 1%;
  width: 10%;
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-bottom: 1%;
  padding-left: 3%;
`;

//python

export const PythonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PyMoveButton = styled.button`
  width: 5%;
  outline: none;
`;

export const PythonEditorContainer = styled.div`
  text-align: left;
  width: 90%;
`;

export const FlexRowWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const PythonBlockWrapper = styled.div``;

export const PythonBlockName = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  padding: 8px;
  font-size: 20px;
  border: 1px solid white;
  background-color: #f0f0f0;
  color: black;
`;

export const PythonOutPut = styled.pre`
  border: 1px solid white;
  height: 380px;
  overflow-y: scroll;
  padding: 10px;
  border-left: 1px solid #f0f0f0;
`;

export const PythonCanvas = styled.div`
  border: 1px solid #f0f0f0;
  height: 400px;
`;

export const funcButton = styled.button`
  background-color: #45586c;
  color: white;
  font-size: 16px;
`;
