/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-09
 */

import React, { useState, useEffect } from "react";
import { roomApi } from "../../api/api-room";
import ClassroomPresenter from "./ClassroomPresenter";
import {useLocalStorage} from "../../util/useLocalStorage";

const ClassroomContainer = ({history}) => {
  const [classroomList, setClassroomList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [room, setRoom] = useLocalStorage('room', {});

  const clickRoom = async (roomId) => {

    let result = null;
    try {
      result = await roomApi.getRoomDetail(roomId);
    } catch (e) {
      console.error(e);
    } finally {
      const roomInfo = {
        roomId: result.data.data.roomId,
        meetingUrl: result.data.data.meetingUrl,
        roomName: result.data.data.roomName,
      };
      setRoom(roomInfo);
      localStorage.setItem("room",JSON.stringify(roomInfo));
      history.push(`/classin/curriculum`); 
    }
  }

  const fetchData = async () => {
    let result = null;
    try {
      result = await roomApi.getAllRooms();
    } catch (e) {
      console.error(e);
    } finally {
      setClassroomList(result.data.list);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ClassroomPresenter
      classroomList={classroomList}
      isLoading={isLoading}
      clickRoom={clickRoom}
    ></ClassroomPresenter>
  );
};

export default ClassroomContainer;
