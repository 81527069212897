/**
 * @author hanbin
 * @email
 * @create date 2020-11-12
 * @modify date 2020-11-18
 */

import React, { useState } from "react";
import AddProjectPresenter from "./AddProjectPresenter";
import { projectApi } from "../../../api/api-project";

const AddProjectContainer = ({ history }) => {
  const [isPythonOn, setPythonOn] = useState(false);

  const [isUrlOn, setIsUrlOn] = useState(false);

  const [urlList, setUrlList] = useState([]);

  const [pythonFile, setPythonFile] = useState([]);

  const [attachFile, setAttachFile] = useState([]);

  const [projectTitle, setProjectTitle] = useState("");

  const [projectContent, setProjectContent] = useState("");

  const onClickPython = () => {
    setPythonOn((p) => !p);
  };

  const onClickUrl = () => {
    setIsUrlOn((p) => !p);
  };

  const addUrl = (url,type) => {
    let projecturl = {
      type: type,
      url: url,
    }
    setUrlList((urls) => urls.concat([projecturl]));
    onClickUrl();
  }

  const deleteUrl = (targetIdx) => {
    if (
      window.confirm(
        `정말로 ${urlList[targetIdx].url} 을 삭제하시겠습니까?`
      )
    ) {
      if (targetIdx >= 0) {
        let newArray = [...urlList];
        newArray.splice(targetIdx, 1);
        setUrlList(newArray);
      }
    }
  };

  //overwrite if same file name exists
  const addPythonFile = (newFile) => {
    let sameFileIdx = pythonFile.findIndex(
      (file) => file.name === newFile.name
    );

    if (sameFileIdx >= 0) {
      if (
        window.confirm(
          `정말로 ${pythonFile[sameFileIdx].name} 파일을 덮어쓰시겠습니까?`
        )
      ) {
        let newArray = [...pythonFile];
        newArray[sameFileIdx] = newFile;
        setPythonFile(newArray);
      }
    } else {
      setPythonFile((p) => p.concat(newFile));
      alert(`${newFile.name} 파일을 저장하였습니다.`);
    }
  };

  const deletePythonFile = (targetFileIdx) => {
    if (
      window.confirm(
        `정말로 ${pythonFile[targetFileIdx].name} 파일을 삭제하시겠습니까?`
      )
    ) {
      if (targetFileIdx >= 0) {
        let newArray = [...pythonFile];
        newArray.splice(targetFileIdx, 1);
        setPythonFile(newArray);
      }
    }
  };

  const deleteAttachFile = (targetFileIdx) => {
    if (
      window.confirm(
        `정말로 ${attachFile[targetFileIdx].name} 파일을 삭제하시겠습니까?`
      )
    ) {
      if (targetFileIdx >= 0) {
        let newArray = [...attachFile];
        newArray.splice(targetFileIdx, 1);
        setAttachFile(newArray);
      }
    }
  };

  const onSubmit = async () => {
    let result = null;
    const projectFormData = new FormData();

    const files = Array.from(attachFile).concat(pythonFile);

    files.map((file, idx) => {
      projectFormData.append("files[" + idx + "]", file);
    });

    urlList.forEach((url,idx) => {
      projectFormData.append("workUrls["+idx+"].url", url.url);
      projectFormData.append("workUrls["+idx+"].type", url.type);
    })

    projectFormData.append("workTitle", projectTitle);
    projectFormData.append("workContent", projectContent);

    try {
      result = await projectApi.createProject(
        projectFormData
      );

      if (result) {
        history.push("/project");
      } else {
        throw new Error(
          "프로젝트 등록에 문제가 생겼습니다. 잠시 후에 시도해주세요."
        );
      }
    } catch (e) {
      alert(e);
    }
  };

  return (
    <AddProjectPresenter
      history={history}
      //python editor toggle
      isPythonOn={isPythonOn}
      isUrlOn={isUrlOn}
      //file state
      pythonFile={pythonFile}
      attachFile={attachFile}
      urlList={urlList}
      //set
      setProjectTitle={setProjectTitle}
      setProjectContent={setProjectContent}
      addPythonFile={addPythonFile}
      deletePythonFile={deletePythonFile}
      setAttachFile={setAttachFile}
      deleteAttachFile={deleteAttachFile}
      deleteUrl={deleteUrl}
      //event handler
      onClickPython={onClickPython}
      onClickUrl={onClickUrl}
      onSubmit={onSubmit}
      addUrl={addUrl}
    />
  );
};

export default AddProjectContainer;
