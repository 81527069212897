/**
 * @author hong
 * @email
 * @create date 2020-11-2
 * @modify date 2020-11-3
 * @desc 스크래치 컴포넌트
 */
import React from "react";
import Iframe from 'react-iframe';
import {
    BoxContainer,
    ButtonSection,
    TitleButton,
    BtnLabel,
} from "../ComponentBoxStyled";
import { FaCode, FaPlay } from 'react-icons/fa';

const ContentsScratch = ({
    url,
    code,
}) => {
    return (
        <BoxContainer>
            <ButtonSection>
                <a target="_blank" href={code}>
                <TitleButton><FaCode size={15}/></TitleButton>
                <BtnLabel>코드 보기</BtnLabel>
                </a>
            </ButtonSection>
            <Iframe url={url}
                allowtransparency="true" 
                width="100%"
                height={800}
                id="myId"
                display="initial"
                position="relative" />
        </BoxContainer>
    );
}

export default ContentsScratch;