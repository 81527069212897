import React, { useEffect, useState } from "react";
import ProjectViewPresenter from "./ProjectViewPresenter";
import { projectApi } from "../../../api/api-project";


const ProjectViewContainer = ({ history, match }) => {

    const [projectDetail, setProjectDetail] = useState({});

    const [attachmentList, setAttachmentList] = useState({
        pdfList: [],
        htmlList: [],
        pyList: [],
        entryList: [],
        scratchList: [],
        imgList: [],
    });

    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        let result = null;
        try {
            result = await projectApi.getProjectDetail(match.params.projectId);
        } catch (e) {
            console.log(e);
        } finally {
            if (result != null) {
                let resultData = result.data.data;
                setProjectDetail(resultData);
                let pdfs = [];
                let pys = [];
                let htmls = [];
                let imgs = [];
                for (let attachment of resultData.attachments) {
                    if (attachment.contentType === "application/pdf") {
                        pdfs.push(attachment);
                    } else if (attachment.contentType === "text/x-python-script" || attachment.contentType === "text/plain") {
                        pys.push(attachment);
                    } else if (attachment.contentType === "text/html") {
                        htmls.push(attachment);
                    } else if (attachment.contentType === "image/png" || attachment.contentType === "image/gif" || attachment.contentType === "image/jpeg") {
                        imgs.push(attachment);
                    }
                }
                let scratchs = [];
                let entrys = [];
                for (let url of resultData.workUrls) {
                    if (url.type === "scratch") {
                        scratchs.push(url);
                    } else if (url.type === "entry") {
                        entrys.push(url);
                    }
                }
                setAttachmentList({
                    pdfList: pdfs,
                    htmlList: htmls,
                    pyList: pys,
                    entryList: entrys,
                    scratchList: scratchs,
                    imgList: imgs,
                });
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <ProjectViewPresenter
            projectDetail={projectDetail}
            pdfList={attachmentList.pdfList}
            scratchList={attachmentList.scratchList}
            entryList={attachmentList.entryList}
            pyList={attachmentList.pyList}
            imgList={attachmentList.imgList}
            history={history}
            isLoading={isLoading}
        >
        </ProjectViewPresenter>
    );
};

export default ProjectViewContainer;