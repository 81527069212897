/**
 * @author jeongin
 * @email
 * @create date 2020-12-15
 * @modify date 2020-12-16
 */

import React, { useState, useEffect } from "react";
import * as s from "./MakeRoomModalStyled"
import { AiOutlineCloseCircle } from 'react-icons/ai';


const MakeRoomModal = ({ onClickModal, addUrl }) => {


    const [manualOn, setManualOn] = useState(false);
    const [hoveringBool, setHoveringBool] = useState(false);

    const [url, setUrl] = useState("");

    const onMouseEnterManual = () => {
        setHoveringBool(true);
    };

    const onMouseLeaveManual = () => {
        setHoveringBool(false);
    };

    const onClickWindow = () => {
        if (!hoveringBool && manualOn) {
            setManualOn(false);
            setHoveringBool(false);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            addUrl(url);
        }
    };

    useEffect(() => {
        document.addEventListener("click", onClickWindow);

        return () => {
            document.removeEventListener("click", onClickWindow);
        };
    });

    return (
        <>
            <s.PopupBox
                onMouseEnter={onMouseEnterManual}
                onMouseLeave={onMouseLeaveManual}
            >
                <s.PopupWrapper>
                    <s.URLCloseButton
                        onClick={() => onClickModal()}>
                            <AiOutlineCloseCircle size={24} />
                    </s.URLCloseButton>
                    <s.UrlText>
                            URL
                    </s.UrlText>
                    <s.UrlInputBox
                        placeholder={"Zoom URL을 입력해주세요."}
                        value={url}
                        onChange={(e) =>
                            setUrl(e.target.value)
                        }
                        onKeyPress={
                            handleKeypress
                        }
                    />
                    <s.SubmitRow>
                        <s.SubmitButton
                            onClick={()=>{addUrl(url)}}
                        >
                            등록하기
                        </s.SubmitButton>
                    </s.SubmitRow>
                </s.PopupWrapper>
            </s.PopupBox>
        </>
    );
};

export default MakeRoomModal;