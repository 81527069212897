import React, { useState, useEffect } from "react";
import SignInPresenter from "./SignInPresenter";
import { loginApi } from "../../api/api-login";
import { useLocalStorage } from "../../util/useLocalStorage"

const SignInContainer = ({ history }) => {

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const [token, setToken] = useLocalStorage('token', "");
  const [userinfo, setUserinfo] = useLocalStorage('userinfo', {});

  const [loginError, setLoginError] = useState(false);

  const onChangeEmail = (e) => {
    e.preventDefault();
    setEmailAddress(e.target.value);

  };
  const onChangePw = (e) => {
    // e.preventDefault();
    setPassword(e.target.value);
  };


  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      loginSuccess();
    }
  };

  // let referer = "/";

  // if (typeof history.location.state !== "undefined") {
  //   referer = history.location.state.referer;
  // }

  // if(isLoggedIn) {
  //   console.log(referer,rest,history, document.referrer);
  //   // return <Redirect to={referer} />
  // };


  const loginSuccess = async () => {

    let result = null;

    try {
      result = await loginApi.postInformation({
        uid: emailAddress,
        upw: password
      });

    } catch (e) {
      console.error(e);
    } finally {
      if (!result.data.success) {
        setLoginError(true)
      } else {
        setToken(result.data.data.token);
        let userinfo = {
          username: result.data.data.username,
          role: result.data.data.roles[0].roleName,
        }
        setUserinfo(userinfo);
        history.goBack();
      }
    }
  }

  return (
    <SignInPresenter
      onChangeEmail={onChangeEmail}
      onChangePw={onChangePw}
      loginSuccess={loginSuccess}
      handleKeypress={handleKeypress}
      loginError={loginError}
    ></SignInPresenter>
  );
};

export default SignInContainer;