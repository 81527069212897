import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  font-size: 5px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default () => (
  <Container>
    <img
      src={require("../static/img/spin-1s-200px.gif")}
      alt="loading.."
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  </Container>
);