/**
 * @author hanbin
 * @email
 * @create date 2020-11-05
 * @modify date 2020-11-17
 */

import React from "react";
import moment from "moment";

import { Link } from "react-router-dom";
import * as s from "./MainpageStyle";

const ContentProjectBox = ({ title, contentList }) => {
  return (
    <s.ContentBox>
      <s.ContentTitle>{title}</s.ContentTitle>
      <s.ListContainer>
        {contentList.map(
          (item, index) =>
            index < 4 && (
              <s.ProjectListItem key={index}>
                <s.ProjectTitle>
                  {item.workTitle &&
                  item.workTitle.length > 8
                    ? item.workTitle.substr(0, 8) + "..."
                    : item.workTitle}
                </s.ProjectTitle>
                <s.ProjectContent>
                  {item.workContent &&
                  item.workContent.length > 30
                    ? item.workContent.substr(0, 30) + "..."
                    : item.workContent}
                </s.ProjectContent>
                <s.ProjectDate>
                  {moment(item.modifiedDate).format(
                    "YYYY.MM.DD"
                  )}
                </s.ProjectDate>
              </s.ProjectListItem>
            )
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "3%",
          }}
        >
          <Link
            to={{
              pathname: "/project",
            }}
          >
            <div
              style={{
                color: "#5e5e5e",
                fontSize: 14,
              }}
            >
              더보기
            </div>
          </Link>
        </div>
      </s.ListContainer>
    </s.ContentBox>
  );
};

export default ContentProjectBox;
