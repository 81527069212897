import styled from "styled-components";

// header 중간
const Container = styled.div`
  width: 100%;
  height: 470px;
  background: url("https://cdn.maiclass.com/maiclass/images/intro/main/main_head_image.png") no-repeat center;
  background-size: cover; 

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const ImageIcon = styled.div`
  background: url("https://cdn.maiclass.com/maiclass/images/intro/main/main_brain_image.png") no-repeat center;
  width: 100%;
  height: 220px;
  position: absolute;
  top: 25%;
  opacity: 0.6;
`;

const Contents = styled.div`
  width: 1080px;
  height: 100%;
  margin: 0 auto;
  position: relative;
`;

const TextBox = styled.div`
  width: 100%;
  position: absolute;
  top: 30%;
`;

const MainTitle = styled.h1`
  font-weight: 400;
  font-size: 46px;
  color: #fff;
  line-height: 1.2em;
`;

const SubTitle = styled(MainTitle)`
  font-weight: bold;
  text-align: right;
`;

const MainStrong = styled.strong`
  font-weight: bold;
  color: #F0B828;
`;

const MainTitleBox = styled.div`
  float: left;
`;

const SubTitleBox = styled.div`
  float: right;
`;

export {
  Container,
  ImageIcon,
  Contents,
  TextBox,
  MainTitle,
  SubTitle,
  MainStrong,
  MainTitleBox,
  SubTitleBox,
};
