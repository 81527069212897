/*********************************
//*** @Author : hong ***
//*** @Date : 2020.11.11 ***
//*** @Title : project view ***
*********************************/

import React, { useState } from "react";
import { HiOutlineArrowCircleLeft } from "react-icons/hi";
import LoginedSidebar from "../../sections/LoginedSidebar";
import NotLoginedSidebar from "../../sections/NotLoginedSidebar";
import ContentsPdf from "../../contents/contentsComponents/ContentsPdf";
import ContentsScratch from "../../contents/contentsComponents/ContentsScratch";
import ContentsEntry from "../../contents/contentsComponents/ContentsEntry";
import ContentsPython from "../../contents/contentsComponents/ContentsPython";
import ContentsImg from "../../contents/contentsComponents/ContentsImg";

import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import * as style from "./ProjectViewPresenterStyled";
import Loader from "../../Loader";
import {tokenCheck} from "../../../util/util";

const ProjectViewPresenter = ({
    projectDetail,
    pdfList,
    scratchList,
    entryList,
    pyList,
    imgList,
    history,
    isLoading,
}) => {
    const [pyIndex, setPyIndex] = useState(0);
    const preClick = () => {
        if (pyIndex > 0) {
            setPyIndex(pyIndex - 1);
        }
    };
    const nextClick = () => {
        if (pyIndex < pyList.length - 1) {
            setPyIndex(pyIndex + 1);
        }
    };
    return (
        <>
            { tokenCheck() ? (<LoginedSidebar />) : (<NotLoginedSidebar />)}
            {
                isLoading ?
                    <Loader />
                    :
                    <style.Container>
                        <style.HeaderSection>
                            <style.ButtonTitleWrapper>
                                <HiOutlineArrowCircleLeft
                                    onClick={() => {
                                        history.push("/project");
                                    }}
                                    size={30}
                                />
                                <style.PrevTitle>내 프로젝트 {'>'}</style.PrevTitle>
                                <style.Title>{projectDetail.workTitle}</style.Title>
                            </style.ButtonTitleWrapper>
                            <style.Button
                                onClick={() => history.push(`/project/update/${projectDetail.workId}`)}
                            >수정/삭제</style.Button>
                        </style.HeaderSection>
                        <style.BoxContainer>
                            <style.ContentText>{projectDetail.workContent}</style.ContentText>
                            {
                                pdfList.map((pdf) =>
                                    <ContentsPdf
                                        key={pdf.id}
                                        url={pdf.fileUrl}
                                    ></ContentsPdf>
                                )
                            }
                            {
                                imgList.map((img) =>
                                    <ContentsImg
                                        key={img.id}
                                        url={img.fileUrl}
                                    ></ContentsImg>
                                )
                            }
                            {
                                scratchList.map((scratch) =>
                                    <ContentsScratch
                                        key={scratch.id}
                                        url={scratch.url}
                                        code={scratch.url.replace('embed', 'editor')}
                                    ></ContentsScratch>
                                )
                            }
                            {
                                entryList.map((entry) =>
                                    <ContentsEntry
                                        key={entry.id}
                                        url={entry.url}
                                        code={entry.url.replace('api/project/iframe', 'ws')}
                                    ></ContentsEntry>
                                )
                            }
                            {
                                pyList.length > 0 ?
                                    <style.PythonSection>
                                        <style.PyMoveButton
                                            onClick={()=>preClick()}
                                        ><FaArrowAltCircleLeft size={20} /></style.PyMoveButton>
                                        <ContentsPython
                                            key={pyList[pyIndex].id}
                                            fileName={pyList[pyIndex].fileName}
                                            fileUrl={pyList[pyIndex].fileUrl}
                                        ></ContentsPython>
                                        <style.PyMoveButton
                                            onClick={()=>nextClick()}
                                        ><FaArrowAltCircleRight size={20} /></style.PyMoveButton>
                                    </style.PythonSection>
                                    : null
                            }
                        </style.BoxContainer>
                    </style.Container>
            }
        </>
    );
};

export default ProjectViewPresenter;