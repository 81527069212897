/**
 * @author hanbin
 * @email
 * @create date 2020-11-12
 * @modify date 2020-11-18
 */

import React, { useState, useEffect } from "react";
import UpdateProjectPresenter from "./UpdateProjectPresenter";
import { projectApi } from "../../../api/api-project";

const UpdateProjectContainer = ({ history, match }) => {
  const [isPythonOn, setPythonOn] = useState(false);
  const [isUrlOn, setIsUrlOn] = useState(false);

  const [urlList, setUrlList] = useState([]);

  const [projectId, setProjectId] = useState("");

  const [pythonFile, setPythonFile] = useState([]);

  const [viewPythonFile, setViewPythonFile] = useState([]);

  const [pythonFileLength, setPythonFileLength] = useState(0);

  const [attachFile, setAttachFile] = useState([]);

  const [viewAttachFile, setViewAttachFile] = useState([]);
  const [delAttachFile, setDelAttachFile] = useState([]);

  const [attachFileLength, setAttachFileLength] = useState(0);

  const [projectTitle, setProjectTitle] = useState("");
  const [projectContent, setProjectContent] = useState("");

  const [isLoading, setIsLoading] = useState(true);


  const onClickPython = () => {
    setPythonOn((p) => !p);
  };

  const onClickUrl = () => {
    setIsUrlOn((p) => !p);
  };

  const addUrl = (url, type) => {
    let projecturl = {
      type: type,
      url: url,
    }
    setUrlList((urls) => urls.concat([projecturl]));
    onClickUrl();
  };

  const deleteUrl = (targetIdx) => {
    if (
      window.confirm(
        `정말로 ${urlList[targetIdx].url} 을 삭제하시겠습니까?`
      )
    ) {
      if (targetIdx >= 0) {
        let newArray = [...urlList];
        newArray.splice(targetIdx, 1);
        setUrlList(newArray);
      }
    }
  };

  const addAttachmentFile = (files) => {
    let newArray = [...files];
    setAttachFile((p) => p.concat(newArray));
    let viewFiles = [];
    newArray.map((file) => {
      let viewFile = {
        name: file.name,
      };
      viewFiles.push(viewFile);
    })
    setViewAttachFile((p) => p.concat(viewFiles));
  };

  const deleteAttachFile = (targetFileIdx) => {
    if (targetFileIdx < attachFileLength) {
      if (
        window.confirm(
          `정말로 ${viewAttachFile[targetFileIdx].name} 파일을 삭제하시겠습니까?`
        )
      ) {
        if (targetFileIdx >= 0) {
          setDelAttachFile((p) => p.concat([viewAttachFile[targetFileIdx].id]));
          let newArray = [...viewAttachFile];
          newArray.splice(targetFileIdx, 1);
          setViewAttachFile(newArray);
          setAttachFileLength(attachFileLength - 1);
        }
      }
    } else {
      if (
        window.confirm(
          `정말로 ${viewAttachFile[targetFileIdx].name} 파일을 삭제하시겠습니까?`
        )
      ) {
        if (targetFileIdx >= 0) {
          let newArray = [...viewAttachFile];
          newArray.splice(targetFileIdx, 1);
          setViewAttachFile(newArray);
          newArray = [...attachFile];
          newArray.splice(targetFileIdx - attachFileLength, 1);
          setAttachFile(newArray);
        }
      }

    }
  };

  //overwrite if same file name exists
  const addPythonFile = (newFile) => {
    let sameFileIdx = viewPythonFile.findIndex(
      (file) => file.name === newFile.name
    );

    if (sameFileIdx >= 0) {
      if (
        window.confirm(
          `정말로 ${viewPythonFile[sameFileIdx].name} 파일을 덮어쓰시겠습니까?`
        )
      ) {
        if (sameFileIdx > pythonFileLength) {
          let newArray = [...pythonFile];
          newArray[sameFileIdx - pythonFileLength] = newFile;
          setPythonFile(newArray);
        }
      }
    } else {
      let viewFile = {
        name: newFile.name,
      };
      setViewPythonFile((p => p.concat([viewFile])));
      setPythonFile((p) => p.concat(newFile));
      alert(`${newFile.name} 파일을 저장하였습니다.`);
    }
  };

  const deletePythonFile = (targetFileIdx) => {
    if (
      window.confirm(
        `정말로 ${viewPythonFile[targetFileIdx].name} 파일을 삭제하시겠습니까?`
      )
    ) {
      if (targetFileIdx >= 0) {
        let newArray = null;
        if(targetFileIdx >= pythonFileLength) {
          newArray = [...pythonFile];
          newArray.splice(targetFileIdx - pythonFileLength, 1);
          setPythonFile(newArray);  
        } else {
          setDelAttachFile((p) => p.concat([viewPythonFile[targetFileIdx].id]));
          setPythonFileLength(pythonFileLength - 1);
        }
        newArray = [...viewPythonFile];
        newArray.splice(targetFileIdx, 1);
        setViewPythonFile(newArray);
      }
    }
  };

  const deleteProject = async () => {
    let result = null;
    try {
      result = await projectApi.deleteProject(projectId);
    } catch(e) {
      console.log(e);
    } finally {
      if(result) {
        history.push(`/project`);
      } else {
        throw new Error(
          "프로젝트 등록에 문제가 생겼습니다. 잠시 후에 시도해주세요."
        );
      }

    }
  }

  const onSubmit = async () => {
    let result = null;
    const projectFormData = new FormData();

    const files = Array.from(attachFile).concat(pythonFile);

    const delFiles = Array.from(delAttachFile);

    delFiles.map((fileId, idx) => {
      projectFormData.append("delFileList[" + idx + "]", fileId);
    })

    files.map((file, idx) => {
      projectFormData.append("files[" + idx + "]", file);
    });

    urlList.forEach((url, idx) => {
      projectFormData.append("workUrls[" + idx + "].url", url.url);
      projectFormData.append("workUrls[" + idx + "].type", url.type);
    })

    projectFormData.append("workId", projectId);
    projectFormData.append("workTitle", projectTitle);
    projectFormData.append("workContent", projectContent);

    try {
      result = await projectApi.updateProject(
        projectFormData
      );

      if (result) {
        history.push(`/project/read/${projectId}`);
      } else {
        throw new Error(
          "프로젝트 등록에 문제가 생겼습니다. 잠시 후에 시도해주세요."
        );
      }
    } catch (e) {
      alert(e);
    }
  };

  const fetchData = async () => {
    let result = null;
    
    try {
      result = await projectApi.getProjectDetail( match.params.projectId);
    } catch (e) {
      console.log(e);
    } finally {
      if (result != null) {
        let resultData = result.data.data;
        setProjectId(resultData.workId);
        setProjectTitle(resultData.workTitle);
        setProjectContent(resultData.workContent);
        let pys = [];
        let attachments = [];
        for (let attachment of resultData.attachments) {
          let temp = {};
          if (attachment.contentType === "text/x-python-script" || attachment.contentType === "text/plain") {
            temp.name = attachment.fileName;
            temp.id = attachment.id;
            temp.url = attachment.fileUrl;
            pys.push(temp);
          } else {
            temp.name = attachment.fileName;
            temp.id = attachment.id;
            temp.url = attachment.fileUrl;
            attachments.push(temp);
          }
        }
        let urls = [];
        for (let url of resultData.workUrls) {
          urls.push({ type: url.type, url: url.url });
        }
        setUrlList(urls);
        setViewAttachFile(attachments);
        setAttachFileLength(attachments.length);
        setViewPythonFile(pys);
        setPythonFileLength(pys.length);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UpdateProjectPresenter
      history={history}
      //python editor toggle
      isPythonOn={isPythonOn}
      isUrlOn={isUrlOn}
      //file state
      urlList={urlList}
      viewAttachFile={viewAttachFile}
      viewPythonFile={viewPythonFile}
      //set
      projectTitle={projectTitle}
      setProjectTitle={setProjectTitle}
      projectContent={projectContent}
      setProjectContent={setProjectContent}
      setViewPythonFile={setViewPythonFile}
      addPythonFile={addPythonFile}
      deletePythonFile={deletePythonFile}
      setViewAttachFile={setViewAttachFile}
      addAttachmentFile={addAttachmentFile}
      deleteAttachFile={deleteAttachFile}
      deleteUrl={deleteUrl}
      //event handler
      onClickPython={onClickPython}
      onClickUrl={onClickUrl}
      onSubmit={onSubmit}
      isLoading={isLoading}
      addUrl={addUrl}
      deleteProject={deleteProject}
    />
  );
};

export default UpdateProjectContainer;
