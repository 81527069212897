import React from "react";
import * as s from "./UpdateProjectStyled";
import { HiOutlineChevronRight, HiOutlineTrash } from "react-icons/hi";
import { FiUpload, FiLink2 } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";
import NotLoginedSidebar from "../../sections/NotLoginedSidebar";
import LoginedSidebar from "../../sections/LoginedSidebar";
import PythonEditor from "./pythonEditor/PythonEditor";
import Loader from "../../Loader";
import {tokenCheck} from "../../../util/util";


const UpdateProjectPresenter = ({
  history,
  isPythonOn,
  projectTitle,
  projectContent,
  viewPythonFile,
  viewAttachFile,
  setProjectTitle,
  setProjectContent,
  addAttachmentFile,
  addPythonFile,
  deletePythonFile,
  deleteAttachFile,
  onClickPython,
  onSubmit,
  isLoading,
  onClickUrl,
  urlList,
  deleteUrl,
  deleteProject,
}) => {
  return (
    <div>
      {tokenCheck() ? (
        <LoginedSidebar />
      ) : (
          <NotLoginedSidebar />
        )}
      {
        isLoading ?
          <Loader />
          :
          <s.Container>
            <s.ContentContainer>
              <s.TitleWrapper>
                <s.PrevTitle
                  onClick={() => history.push("/project")}
                >
                  프로젝트
            </s.PrevTitle>
                <HiOutlineChevronRight />
                <s.CurTitle>수정</s.CurTitle>
                <s.DelButton
                  onClick={()=>{deleteProject()}}
                ><HiOutlineTrash size={24}/></s.DelButton>
              </s.TitleWrapper>
              <s.AddContainer>
                <s.AddContentWrapper>
                  <s.TitleText>제목</s.TitleText>
                  <s.TitleInputBox
                    placeholder={"제목을 입력하세요."}
                    value={projectTitle}
                    onChange={(e) =>
                      setProjectTitle(e.target.value)
                    }
                  ></s.TitleInputBox>
                </s.AddContentWrapper>
                <s.AddContentWrapper>
                  <s.TitleText>내용</s.TitleText>
                  <s.ContentTextArea
                    placeholder={"글을 작성하세요."}
                    value={projectContent}
                    onChange={(e) =>
                      setProjectContent(e.target.value)
                    }
                  ></s.ContentTextArea>
                </s.AddContentWrapper>
              </s.AddContainer>
              <s.FlexRowBetweenDiv>
                <s.FlexRowGapDiv>
                  <s.URLInputWrapper>
                    <s.URLInputLabel for="url">
                      URL 등록 <FiLink2 size={32} />
                    </s.URLInputLabel>
                    <s.URLInputButton
                      id="url"
                      onClick={() => onClickUrl()}
                    />
                  </s.URLInputWrapper>

                  {Array.from(urlList).map((item, index) => (
                    <s.UploadedFileBlock
                      key={index}
                    >
                      {item.type}
                      <TiDelete
                        size={24}
                        style={{
                          position: "absolute",
                          transform:
                            "translate3d(-0.2em, -1.2em,0)",
                        }}
                        onClick={() => deleteUrl(index)}
                      />
                    </s.UploadedFileBlock>
                  ))}
                </s.FlexRowGapDiv>
              </s.FlexRowBetweenDiv>
              <s.FlexRowBetweenDiv>
                <s.FlexRowGapDiv>
                  <s.FileInputWrapper>
                    <s.FileInputLabel for="file">
                      파일 첨부 <FiUpload size={32} />
                    </s.FileInputLabel>
                    <s.FileInput
                      type="file"
                      id="file"
                      multiple
                      onChange={(e) => {
                        addAttachmentFile(e.target.files);
                      }}
                    />
                  </s.FileInputWrapper>

                  {Array.from(viewAttachFile).map((item, index) => (
                    <s.UploadedFileBlock
                      key={index}
                    >
                      {item.name}
                      <TiDelete
                        size={24}
                        style={{
                          position: "absolute",
                          transform:
                            "translate3d(-0.2em, -1.2em,0)",
                        }}
                        onClick={() => deleteAttachFile(index)}
                      />
                    </s.UploadedFileBlock>
                  ))}
                </s.FlexRowGapDiv>
                <s.SubmitButton onClick={onSubmit}>
                  등록하기
            </s.SubmitButton>
              </s.FlexRowBetweenDiv>
              <s.FlexRowGapDiv
                style={{
                  marginTop: "5%",
                  marginBottom: "5%",
                  width: "100%",
                }}
              >
                <s.PythonToggleButton onClick={onClickPython}>
                  파이썬 연습장 +
            </s.PythonToggleButton>
                {viewPythonFile &&
                  viewPythonFile.map((file, index) => (
                    <s.UploadedFileBlock key={index}>
                      {file.name}
                      <TiDelete
                        size={24}
                        style={{
                          position: "absolute",
                          transform:
                            "translate3d(-0.1em, -1.3em,0)",
                        }}
                        onClick={() => deletePythonFile(index)}
                      />
                    </s.UploadedFileBlock>
                  ))}
              </s.FlexRowGapDiv>

              {isPythonOn ? (
                <PythonEditor
                  addPythonFile={addPythonFile}
                />
              ) : (
                  ""
                )}
            </s.ContentContainer>
          </s.Container>
      }
    </div>
  );
};

export default UpdateProjectPresenter;
